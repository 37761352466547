export default {
  namespaced: true,
  state: {
    // 是否为手机
    isMobile: false,
    // 是否为平板
    isTablet: false,
    // 是否为桌面
    isDesktop: true,
    // 是否切换到了全屏
    isFullscreen: false,
    // 侧边栏展开收缩
    menusCollapse: false
  },
  mutations: {
    /**
     * @description 设置设备类型
     * @param {Object} state vuex state
     * @param {String} type 设备类型，可选值为 Mobile、Tablet、Desktop
     */
    setDevice: (state, type) => {
      state.isMobile = false
      state.isTablet = false
      state.isDesktop = false
      state[`is${type}`] = true
    },
    /**
     * 设置侧边栏展开关闭
     * @param state
     * @param status
     */
    changeCol (state, status) {
      state.menusCollapse = status
    }
  },
  actions: {
  },
  modules: {
  }
}
