import request from '@/utils/request'

/**
 * 电话 获取该服务商的电话坐席
 * @returns {*}
 */
export function get_tel_no (params) {
  return request({
    url: 'users/get_tel_no',
    method: 'get',
    params
  })
}

/**
 * 电话 拨打电话记录
 * @returns {*}
 */
export function call_phone (data) {
  return request({
    url: `users/call_phone`,
    method: 'post',
    data
  })
}

/**
 * 电话 获取该服务商的电话是否欠费
 * @returns {*}
 */
export function is_phone_overdue (params) {
  return request({
    url: 'users/is_phone_overdue',
    method: 'get',
    params
  })
}
