import { cloneDeep } from 'lodash'

/**
 * 根据当前路由，找到顶部菜单名称
 * @param to
 * @param menuList
 * @returns {*|null}
 */
export function getHeaderName (to, menuList) {
  const allMenus = []
  menuList.forEach((menu) => {
    const headerName = menu.path || ''
    const menus = transferMenu(menu, headerName)
    allMenus.push({
      path: menu.path,
      header: headerName
    })
    menus.forEach((item) => allMenus.push(item))
  })
  const currentMenu = allMenus.find((item) => {
    if (item.path === to.path) {
      return true
    } else {
      return to.path === getPath(to, item.path)
    }
  })
  return currentMenu ? currentMenu.header : null
}

function getPath (to, path) {
  let params = []
  let query = []
  Object.keys(to.params).forEach((item) => {
    params.push(to.params[item])
  })
  Object.keys(to.query).forEach((item) => {
    query.push(item + '=' + to.query[item])
  })
  return path + (params.length ? '/' + params.join('/') : '') + (query.length ? '?' + query.join('&') : '')
}

export function transferMenu (menu, headerName) {
  if (menu.children && menu.children.length) {
    return menu.children.reduce((all, item) => {
      all.push({
        path: item.path,
        header: headerName
      })
      const foundChildren = transferMenu(item, headerName)
      return all.concat(foundChildren)
    }, [])
  } else {
    return [menu]
  }
}

/**
 * 根据当前路由，找打顶部菜单名称
 * @param {Array} menuList 所有路径
 * @returns {*}
 */
export function getHeaderSider (menuList) {
  return menuList.filter((item) => item.pid === 0)
}

/**
 * 根据当前路由，找以及菜单名称
 * @param {String} path 当前路径
 * @param {Array} menuList 所有路径
 * @returns {*}
 */
export function getOneHeaderName (menuList, path) {
  return menuList.filter((item) => item.path === path)
}

/**
 * 根据当前顶栏菜单 name，找到对应的二级菜单
 * @param {Array} menuList 所有的二级菜单
 * @param {String} headerName 当前顶栏菜单的 name
 * @returns {*}
 */
export function getMenuSider (menuList, headerName = '') {
  if (headerName) {
    return menuList.filter((item) => item.path === headerName)
  } else {
    return menuList
  }
}

/**
 * 获取子菜单
 * @param to
 * @param menuList
 * @returns {[]|defaults.watch.openNames|*|*[]}
 */
export function getSiderSubmenu (to, menuList) {
  const allMenus = []
  menuList.forEach((menu) => {
    const menus = transferSubMenu(menu, [])
    allMenus.push({
      path: menu.path,
      openNames: []
    })
    menus.forEach((item) => allMenus.push(item))
  })
  const currentMenu = allMenus.find((item) => {
    if (item.openNames.length) {
      return item.path === to.path || to.path === getPath(to, item.path)
    }
  })
  return currentMenu ? currentMenu.openNames : []
}

/**
 * 转移子菜单
 * @param menu
 * @param openNames
 * @returns {*|{path: *, openNames: *}[]}
 */
export function transferSubMenu (menu, openNames) {
  if (menu.children && menu.children.length) {
    const itemOpenNames = openNames.concat([menu.path])
    return menu.children.reduce((all, item) => {
      all.push({
        path: item.path,
        openNames: itemOpenNames
      })
      const foundChildren = transferSubMenu(item, itemOpenNames)
      return all.concat(foundChildren)
    }, [])
  } else {
    return [menu].map((item) => {
      return {
        path: item.path,
        openNames: openNames
      }
    })
  }
}

/**
 * 递归获取所有子菜单
 * @param menuList
 * @returns {*[]}
 */
export function getAllSiderMenu (menuList) {
  let allMenus = []

  menuList.forEach((menu) => {
    if (menu.children && menu.children.length) {
      const menus = getMenuChildren(menu)
      menus.forEach((item) => allMenus.push(item))
    } else {
      allMenus.push(menu)
    }
  })

  return allMenus
}

/**
 * 获取菜单孩子
 * @param menu
 * @returns {*|*[]}
 */
export function getMenuChildren (menu) {
  if (menu.children && menu.children.length) {
    return menu.children.reduce((all, item) => {
      const foundChildren = getMenuChildren(item)
      return all.concat(foundChildren)
    }, [])
  } else {
    return [menu]
  }
}

/**
 * 将菜单转为平级
 * @param menuList
 * @param newList
 * @returns {*}
 */
export function flattenSiderMenu (menuList, newList) {
  menuList.forEach((menu) => {
    let newMenu = {}
    for (let i in menu) {
      if (i !== 'children') newMenu[i] = cloneDeep(menu[i])
    }
    newList.push(newMenu)
    menu.children && flattenSiderMenu(menu.children, newList)
  })
  return newList
}

/**
 * 判断列表1中是否包含了列表2中的某一项
 * 因为用户权限 access 为数组，includes 方法无法直接得出结论
 * @param list1
 * @param list2
 * @returns {boolean}
 */
export function includeArray (list1, list2) {
  let status = false
  if (list1 === true) {
    return true
  } else {
    if (typeof list2 !== 'object') {
      return false
    }
    list2.forEach((item) => {
      if (list1.includes(item)) status = true
    })
    return status
  }
}

export function getMenuopen (to, menuList) {
  const allMenus = []
  menuList.forEach((menu) => {
    const menus = transMenu(menu, [])
    allMenus.push({
      path: menu.path,
      openNames: []
    })
    menus.forEach((item) => allMenus.push(item))
  })
  const currentMenu = allMenus.find((item) => item.path === to.path)
  return currentMenu ? currentMenu.openNames : []
}

function transMenu (menu, openNames) {
  if (menu.children && menu.children.length) {
    const itemOpenNames = openNames.concat([menu.path])
    return menu.children.reduce((all, item) => {
      all.push({
        path: item.path,
        openNames: itemOpenNames
      })
      const foundChildren = transMenu(item, itemOpenNames)
      return all.concat(foundChildren)
    }, [])
  } else {
    return [menu].map((item) => {
      return {
        path: item.path,
        openNames: openNames
      }
    })
  }
}

/**
 * 多级嵌套数组处理成一维数组
 * @param arr
 * @returns {*|boolean}
 */
export const formatFlatteningRoutes = (arr) => {
  if (arr.length <= 0) return false
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].children) {
      arr = arr.slice(0, i + 1).concat(arr[i].children, arr.slice(i + 1))
    }
  }
  return arr
}
