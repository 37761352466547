import { tableDelApi } from '@/api/common'

/**
 * 全局删除冒泡框
 * @param formRequestPromise
 * @param Title
 * @returns {Promise<unknown>}
 */
export function modalSure (formRequestPromise, Title) {
  return new Promise((resolve, reject) => {
    this.$Modal.confirm({
      title: Title,
      content: '<p>确定要删除吗？</p><p>删除后将无法恢复，请谨慎操作！</p>',
      onOk: () => {
        formRequestPromise.then(({ data }) => {
        }).catch(() => {
          this.$Message.error('表单加载失败')
        })
      },
      onCancel: () => {
        this.$Message.info('取消成功')
      }
    })
  })
}

/**
 * 全局弹出框
 * @param delfromData
 * @returns {Promise<unknown>}
 */
export function overallModal (delfromData) {
  return new Promise((resolve, reject) => {
    let content = `<p>确定要${delfromData.title}吗？</p>`
    if (delfromData.info !== undefined) {
      content = content + `<p>${delfromData.info}</p>`
    }
    this.$Modal.confirm({
      title: delfromData.title,
      content: content,
      loading: true,
      onOk: () => {
        setTimeout(() => {
          this.$Modal.remove()
          if (delfromData.success) {
            delfromData.success.then(async (res) => {
              resolve(res)
            }).catch((res) => {
              reject(res)
            })
          } else {
            tableDelApi(delfromData).then(async (res) => {
              resolve(res)
            }).catch((res) => {
              reject(res)
            })
          }
        }, 300)
      },
      onCancel: () => {
        this.$Message.info('取消成功')
      }
    })
  })
}
