<template>
  <Modal
    v-model="modal"
    title="上传图片"
    width="60%"
    @on-ok="handleSubmitUpload"
    @on-cancel="handleClear">
    <div>
      <Form
        ref="formValidate"
        :model="formValidate"
        :rules="ruleValidate"
        :label-width="100">
        <FormItem label="上传方式：" prop="type">
          <RadioGroup v-model="formValidate.type" @on-change="handleRadioChange">
            <Radio label="0">本地上传</Radio>
            <Radio label="1">网络上传</Radio>
            <Radio label="2">扫码上传</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="上传至分组：" prop="region" v-show="formValidate.type == 0 || formValidate.type == 1">
          <el-cascader
            class="form-width"
            v-model="formValidate.region"
            :props="props"
            :options="categoryList"
            @change="handleChange"></el-cascader>
        </FormItem>
        <FormItem label="上传图片：" prop="region" v-if="formValidate.type == 0">
          <div class="acea-row">
            <div class="uploadCont">
              <el-upload
                ref="upload"
                :action="fileUrl"
                list-type="picture-card"
                :on-change="fileChange"
                :file-list="formValidate.imgList"
                :auto-upload="false"
                :data="uploadData"
                :headers="header"
                :before-upload="beforeUpload"
                :multiple="true"
                :limit="20">
                <i slot="default" class="el-icon-plus"></i>
                <div
                  slot="file"
                  slot-scope="{ file }"
                  draggable="false"
                  @dragstart="handleDragStart($event, file)"
                  @dragover="handleDragOver($event, file)"
                  @dragenter="handleDragEnter($event, file)"
                  @dragend="handleDragEnd($event, file)">
                  <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                  <i class="el-icon-error btndel" @click="handleWebRemove(file)" />
                </div>
              </el-upload>
              <div class="tips">建议上传图片最大宽度750px，不超过3MB；仅支持jpeg、png格式，可拖拽调整上传顺序</div>
            </div>
          </div>
        </FormItem>
        <FormItem label="网络图片：" prop="region" v-if="formValidate.type == 1">
          <el-input class="form-width" v-model="webImgUrl" placeholder="请网络图片地址"></el-input>
          <span class="tq-text" @click="getImg">提取照片</span>
        </FormItem>
        <template v-if="formValidate.type == 1">
          <div class="img-box pl100">
            <div
              v-for="(item, index) in formValidate.imgList"
              :key="index"
              class="pictrue"
              draggable="false"
              @dragstart="handleDragStart($event, item)"
              @dragover.prevent="handleDragOver($event, item)"
              @dragenter="handleDragEnter($event, item)"
              @dragend="handleDragEnd($event, item)">
              <img :src="item.url" />
              <i class="el-icon-error btndel" @click="handleRemove(index)" />
            </div>
          </div>
        </template>

        <div class="code-image" v-if="formValidate.type == 2">
          <div class="left">
            <FormItem label="上传至分组：" prop="region">
              <el-cascader
                class="form-width"
                v-model="formValidate.region"
                :props="props"
                :options="categoryList"
                @change="handleChange"
              ></el-cascader>
            </FormItem>
            <FormItem label="二维码：" prop="region">
              <div class="code" ref="qrCodeUrl"></div>
              <div class="trip">扫描二维码，快速上传手机图片</div>
              <div class="trip-small">建议使用手机浏览器</div>
            </FormItem>
          </div>
          <div class="right">
            <el-button size="small" @click="scanUploadGet">刷新图库</el-button>
            <div class="tip">刷新图库按钮，可显示移动端上传成功的图片</div>
            <div class="img-box">
              <div
                v-for="(item, index) in formValidate.imgList"
                :key="index"
                class="pictrue"
                draggable="false"
                @dragstart="handleDragStart($event, item)"
                @dragover.prevent="handleDragOver($event, item)"
                @dragenter="handleDragEnter($event, item)"
                @dragend="handleDragEnd($event, item)">
                <img :src="item.att_dir" />
                <i class="el-icon-error btndel" @click="handleWebRemove(item)" />
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  </Modal>
</template>

<script>
import Setting from '@/config/setting'
import compressImg from '@/utils/compressImg'
import {
  getCookies
} from '@/utils/cookies'
import config from '@/config'
import {
  scanUploadGetApi,
  scanUploadQrcodeApi,
  onlineUploadApi,
  moveFileOrCategoryApi,
  fileUploadApi
} from '@/api/common'
import QRCode from 'qrcodejs2'

export default {
  name: 'UploadImg',
  // 支柱道具属性
  props: {
    // 分类列表
    categoryList: {
      default: () => {
        return []
      }
    }
  },
  // 组件生效
  components: {},
  data () {
    return {
      modal: false,

      formValidate: {
        type: '0',
        region: '',
        imgList: []
      },
      ruleValidate: {
        type: [{ required: true, message: '请选择上传方式', trigger: 'change' }]
      },
      props: { checkStrictly: true, emitPath: false, label: 'title', value: 'id' },

      // 上传配置
      fileUrl: Setting.apiBaseURL + '/file/upload',
      header: {
        'Authori-zation': 'Bearer ' + getCookies(config.tokenStoreName)
      },
      uploadData: {},

      webImgUrl: '',
      scanToken: ''
    }
  },
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
  },
  // 方法集合
  methods: {
    // 处理选择上传方式
    handleRadioChange (type) {
      this.formValidate.type = type
      this.formValidate.imgList = []
      clearInterval(this.time)
      this.time = undefined
      if (type == 2) {
        this.scanUploadQrcode()
        this.time = setInterval((e) => {
          this.scanUploadGet()
        }, 2000)
      }
    },
    // 处理分类选择
    handleChange () {},
    // 文件验证
    async fileChange (file, fileList) {
      if (file.size >= 2097152) {
        await this.comImg(file.raw).then((res) => {
          fileList.map((e) => {
            if (e.uid === file.uid) {
              e.raw = res
            }
          })
          this.formValidate.imgList = fileList
        })
      } else {
        this.formValidate.imgList = fileList
      }
    },
    comImg (file) {
      return new Promise((resolve, reject) => {
        compressImg(file).then((res) => {
          resolve(res)
        })
      })
    },
    // 上传前握手
    beforeUpload (file) {},
    // 移动
    handleDragStart (e, item) {
      this.dragging = item
    },
    handleDragEnd (e, item) {
      this.dragging = null
    },
    handleDragOver (e) {
      e.dataTransfer.dropEffect = 'move'
    },
    handleDragEnter (e, item) {
      e.dataTransfer.effectAllowed = 'move'
      if (item === this.dragging) {
        return
      }
      const newItems = [...this.formValidate.imgList]
      const src = newItems.indexOf(this.dragging)
      const dst = newItems.indexOf(item)
      newItems.splice(dst, 0, ...newItems.splice(src, 1))
      this.formValidate.imgList = newItems
    },
    // 移除
    handleWebRemove (file) {
      let index = this.formValidate.imgList.findIndex((e) => {
        return e.url == file.url
      })
      this.formValidate.imgList.splice(index, 1)
    },
    handleRemove (index) {
      this.formValidate.imgList.splice(index, 1)
    },
    // 获取图片
    getImg () {
      if (!this.webImgUrl) {
        this.$Message.error('请先输入图片地址')
        return
      }
      this.formValidate.imgList.push({
        url: this.webImgUrl
      })
    },
    // 刷新图库
    creatQrCode (url) {
      this.$refs.qrCodeUrl.innerHTML = ''
      const qrcode = new QRCode(this.$refs.qrCodeUrl, {
        text: url, // 需要转换为二维码的内容
        width: 160,
        height: 160,
        colorDark: '#000000',
        colorLight: '#ffffff',
        correctLevel: QRCode.CorrectLevel.H
      })
      console.log(qrcode)
    },
    scanUploadQrcode () {
      scanUploadQrcodeApi(this.formValidate.region).then((res) => {
        this.creatQrCode(res.data.url)
        this.scanToken = res.data.url
      })
    },
    scanUploadGet () {
      let token = this.scanToken.split('token=')[1]
      scanUploadGetApi(token).then((res) => {
        this.formValidate.imgList = res.data
      })
    },
    handleClear () {
      this.modal = false
      this.initData()
    },
    initData () {
      this.formValidate.type = '0'
      this.formValidate.region = 0
      this.scanToken = ''
      this.webImgUrl = ''
      this.formValidate.imgList = []
      clearInterval(this.time)
      this.time = undefined
    },
    uploadItem (file) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('file', file)
        formData.append('pid', this.formValidate.region)
        fileUploadApi(formData, getCookies(config.tokenStoreName)).then((res) => {
          if (res.code == 200) {
            resolve(res)
            // this.$emit('uploadImgSuccess', res.data);
          } else {
            this.loading = false
            this.$message({
              message: '上传失败',
              type: 'error',
              duration: 1000
            })
          }
        }).catch((err) => {
          this.loading = false
          this.$Message.error(err.msg)
        })
      })
    },
    async handleSubmitUpload () {
      if (this.formValidate.type == 0) {
        this.uploadData = {
          pid: this.formValidate.region
        }
        if (this.formValidate.imgList.length) {
          if (this.loading) return
          this.loading = true
          for (let i = 0; i < this.formValidate.imgList.length; i++) {
            const file = this.formValidate.imgList[i].raw
            await this.uploadItem(file)
            if (i == this.formValidate.imgList.length - 1) {
              this.$Message.success('上传成功')
              this.$emit('uploadSuccess')
              this.modal = false
              this.loading = false
              this.initData()
            }
          }
        }
      } else if (this.formValidate.type == 1) {
        let urls = this.formValidate.imgList.map((e) => {
          return e.url
        })
        if (urls.length) {
          if (this.loading) return
          this.loading = true
          onlineUploadApi({ pid: this.formValidate.region, images: urls }).then((res) => {
            this.$Message.success('上传成功')
            this.$emit('uploadSuccess')
            this.modal = false
            this.loading = false
            this.initData()
          }).catch((err) => {
            this.loading = false
            this.$Message.error(err.msg)
          })
        }
      } else if (this.formValidate.type == 2) {
        let attId = this.formValidate.imgList.map((e) => {
          return e.att_id
        })
        moveFileOrCategoryApi({ pid: this.formValidate.region, images: attId }).then((res) => {
          this.$Message.success('上传成功')
          this.$emit('uploadSuccess')
          this.modal = false
          this.initData()
        })
      }
    }
  },
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
    clearInterval(this.time)
    this.time = undefined
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog__title{
  font-size: 16px;
}
.main{
  min-height: 500px
}
.pictrue {
  width: 60px;
  height: 60px;
  border: 1px dotted rgba(0, 0, 0, 0.1);
  margin-right: 10px;
  position: relative;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}
.btndel {
  position: absolute;
  z-index: 1;
  font-size: 18px;
  right: -5px;
  top: -5px;
  color: #999;
}
.form-width{
  width: 280px;
}
.tq-text{
  margin-left: 14px;
  font-size: 12px;
  font-weight: 400;
  color: #1890FF;
  cursor: pointer;
}
/deep/ .el-upload--picture-card,
/deep/ .el-upload-list--picture-card .el-upload-list__item{
  width: 64px;
  height: 64px;
  line-height: 72px;
  overflow: inherit;
}
/deep/ .el-upload--picture-card,
/deep/ .el-upload-list--picture-card .el-upload-list__item img{
  width: 64px;
  height: 64px;
  border-radius: 6px;
  object-fit: cover;
}
.pl100{
  padding-left: 100px;
}
.img-box{
  display: flex;
  flex-wrap: wrap;
}
.tips{
  font-size: 12px;
  color: #BBBBBB;
}
.code-image{
  display: flex;
  margin-top: 12px;
  .left {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
    align-items: center;
    .code{
      border: 1px solid #DDDDDD;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 200px;
      height: 200px;
      border-radius: 4px;
      .code-img{
        width: 160px;
        height: 160px;
      }
    }
    .form-width{
      width: 200px;
    }
    .code{
      margin-bottom: 14px;
    }
    .trip{
      color: #333333;
      text-align: center;
      line-height: 18px;
    }
    .trip-small{
      font-size: 12px;
      font-weight: 400;
      color: #BBBBBB;
      text-align: center;
      line-height: 16px;
    }
}
  .right{
    margin-top: 62px;
    .tip{
      font-size: 12px;
      font-weight: 400;
      color: #BBBBBB;
      margin: 10px 0
    }
  }
}
</style>
