import { cloneDeep } from 'lodash'
import { includeArray } from '@/libs/menus'

export default {
  namespaced: true,
  state: {
    // 顶部菜单
    headerData: [],
    // 侧栏菜单
    sliderData: [],
    // 菜单名称
    menusName: getMenusName(),
    // 当前顶栏菜单的 name
    headerName: '',
    // 展开的子菜单 name 集合
    openNames: [],
    // 当前所在菜单的 path
    activePath: '',
    // 当前打开的菜单路径
    openMenus: [],
    // 一级菜单名称
    oneMenuName: '',

    childMenuList: [],

    oneLvMenus: [],
    oneLvRoutes: []
  },
  mutations: {
    // 设置菜单名称
    setMenusNav: (state, menuList) => {
      state.menusName = menuList
    },
    /**
     * 设置顶部栏菜单
     * @param state
     * @param menus
     */
    setHeaderData: (state, menus) => {
      state.headerData = menus
    },
    /**
     * 设置侧边栏菜单
     * @param state
     * @param menus
     */
    setSliderData: (state, menus) => {
      state.sliderData = menus
    },
    /**
     * 设置当前所在菜单的 path，用于侧栏菜单高亮当前项
     * @param state
     * @param path
     */
    setActivePath: (state, path) => {
      state.activePath = path
    },
    /**
     * 当前打开的菜单路径
     * @param state
     * @param openList
     */
    setOpenMenus: (state, openList) => {
      state.openMenus = openList
    },
    /**
     * 设置当前所在菜单的全部展开父菜单的 names 集合
     * @param state
     * @param names
     */
    setOpenNames: (state, names) => {
      state.openNames = names
    },
    /**
     * 设置当前顶栏菜单 name
     * @param state
     * @param name
     */
    setHeaderName: (state, name) => {
      state.headerName = name
    },
    /**
     * 设置侧边栏菜单
     * @param state
     * @param menu
     */
    setOpenMenuName: (state, menu) => {
      state.oneMenuName = menu
    },
    /**
     * 设置孩子
     * @param state
     * @param list
     */
    setChildMenuList: (state, list) => {
      state.childMenuList = list
    },
    /**
     * 设置第一个Menus
     * @param state
     * @param oneLvMenus
     */
    setOneLvMenus: (state, oneLvMenus) => {
      state.oneLvMenus = oneLvMenus
    },
    /**
     * 设置一个路由
     * @param state
     * @param oneLvMenus
     */
    setOneLvRoute: (state, oneLvMenus) => {
      state.oneLvRoutes = oneLvMenus
    }
  },
  actions: {
    /**
     * 设置顶栏
     * @param commit
     * @param state
     * @param data
     */
    setHeader ({ commit, state }, data) {
      commit('SET_HEADER', data)
    },
    /**
     * 设置侧边栏
     * @param commit
     * @param state
     * @param data
     */
    setSlider ({ commit, state }, data) {
      commit('SET_SLIDER', data)
    }
  },
  modules: {
  },
  getters: {}
}

// 根据menu配置的权限，过滤菜单
function filterMenu (menuList, access, lastList) {
  menuList.forEach((menu) => {
    let menuAccess = menu.auth

    if (!menuAccess || includeArray(menuAccess, access)) {
      let newMenu = {}
      for (let i in menu) {
        if (i !== 'children') newMenu[i] = cloneDeep(menu[i])
      }
      if (menu.children && menu.children.length) newMenu.children = []

      lastList.push(newMenu)
      menu.children && filterMenu(menu.children, access, newMenu.children)
    }
  })
  return lastList
}

// 递归处理顶部菜单问题
function getChilden (data) {
  if (data.children) {
    return getChilden(data.children[0])
  }
  return data.path
}

function getMenusName () {
  let storage = window.localStorage
  let menuList = JSON.parse(storage.getItem('menuList'))
  if (typeof menuList !== 'object' || menuList === null) {
    menuList = []
  }
  return menuList
}
