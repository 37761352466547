import request from '@/utils/request'

// =============================
// TODO: DIY配置管理国度
// =============================
/**
 * 页面链接-获取分类
 * @returns {*}
 */
export function getPageCategoryApi () {
  return request({
    url: 'diy/get_page_category',
    method: 'get'
  })
}

/**
 * 页面链接-获取链接；
 * @param id
 * @returns {*}
 */
export function getPageLinkApi (id) {
  return request({
    url: `diy/get_page_link/${id}`,
    method: 'get'
  })
}
