import E from 'wangeditor' // npm 安装
// const E = window.wangEditor // CDN 引入的方式
import bus from '@/utils/bus'

// 获取必要的变量，这些在下文中都会用到
// eslint-disable-next-line no-unused-vars
const { $, BtnMenu, DropListMenu, PanelMenu, DropList, Panel, Tooltip } = E
// eslint-disable-next-line no-unused-vars
var _this = null
export default class AlertMenu extends BtnMenu {
  constructor (editor) {
    _this = editor
    // data-title属性表示当鼠标悬停在该按钮上时提示该按钮的功能简述
    const $elem = E.$(
      `<div class="w-e-menu" data-title="视频">
                <div class="iconfont iconshipin"></div>
            </div>`
    )
    super($elem, editor)
  }

  // 菜单点击事件
  clickHandler () {
    bus.$emit('Video')
  }

  tryChangeActive () {
    this.active()
  }
}
