import request from '@/utils/request'

/**
 * 登录
 * @param data
 * @returns {*}
 * @constructor
 */
export function AccountLoginApi (data) {
  return request({
    url: '/super_login',
    method: 'post',
    data
  })
}


/**
 * 获取管理员信息
 * @returns {*}
 */
export function getSystemAdminInfoApi () {
  return request({
    url: '/system/super_info',
    method: 'get'
  })
}

/**
 * 获取验证码
 * @returns {*}
 */
export function getplatform_verify_code () {
  return request({
    url: '/platform_verify_code',
    method: 'get'
  })
}
/**
 * 获取验证码
 * @param data
 * @returns {*}
 * @constructor
 */
export function getplatform_verify (data) {
  return request({
    url: '/platform_verify',
    method: 'post',
    data
  })
}


/**
 * 退出登录
 * @returns {*}
 * @constructor
 */
export function AccountLogoutApi () {
  return request({
    url: '/system/logout',
    method: 'get'
  })
}


/**
 * 更新管理信息接口
 * @param data
 * @returns {*}
 * @constructor
 */
export function update_admin (data) {
  return request({
    url: '/platform/facilitator/update_admin',
    method: 'post',
    data
  })
}

/**
 * 超级管理员登录
 * @param data
 * @returns {*}
 * @constructor
 */
export function superLoginApi (data) {
  return request({
    url: '/superman_login',
    method: 'post',
    data
  })
}
/**
 * 超管 获取管理员信息
 * @returns {*}
 */
export function systemadmin_info () {
  return request({
    url: '/system/admin_info',
    method: 'get'
  })
}

/**
 * 获取平台管理基本信息接口
 * @returns {*}
 */
export function facilitatorinfo () {
  return request({
    url: '/platform/facilitator/info',
    method: 'get'
  })
}

/**
 * 获取账号设置信息接口
 * @returns {*}
 */
export function account_setting () {
  return request({
    url: '/platform/facilitator/account_setting',
    method: 'get'
  })
}
