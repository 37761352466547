export default {
  // ==================================
  // TODO: 基本配置国度
  // ==================================
  /**
   * @description 配置显示在浏览器标签的title
   */
  title: '一键救援',
  /**
   * @description 是否使用国际化，默认为false
   *              如果不使用，则需要在路由中给需要在菜单中展示的路由设置meta: {title: 'xxx'}
   *              用来在菜单中显示文字
   */
  useI18n: false,

  // ==================================
  // TODO: 路由配置国度
  // ==================================
  // 路由前缀
  routePre: '/admin',
  // 权限前缀
  uniquePrefix: 'superman:',
  // 不需要登录的路由
  whiteList: ['/login', '/new_login','/super_login'],
  // 自定义解析菜单接口数据
  parseMenu: null,
  // 默认打开的首页的路由name值，默认为home
  homeName: 'home_index',
  // 路由模式，可选值为 history 或 hash
  routerMode: 'history',
  // 页面切换时，是否显示模拟的进度条
  showProgressBar: true,

  // ==================================
  // TODO: 导航配置国度
  // ==================================
  // 首页tab显示标题, null会根据菜单自动获取
  homeTitle: '主页',
  // 首页路径, null会自动获取
  homePath: null,
  // 顶栏是否显示主题设置按钮
  showSetting: true,
  // 开启多页签是否缓存组件
  tabKeepAlive: true,
  // 是否折叠侧边栏
  collapse: false,
  // 侧边栏风格: light(亮色), dark(暗色)
  sideStyle: 'dark',
  // 顶栏风格: light(亮色), dark(暗色), primary(主色)
  headStyle: 'dark',
  // 标签页风格: defaults(默认), dot(圆点), card(卡片)
  tabStyle: 'default',
  // 布局风格: side(默认), top(顶栏菜单), mix(混合菜单)
  layoutStyle: 'side',
  // 侧边栏菜单风格: defaults(默认), mix(双排菜单)
  sideMenuStyle: 'default',
  // 是否固定侧栏
  fixedSidebar: true,
  // 是否固定顶栏
  fixedHeader: true,
  // 是否固定主体
  fixedBody: true,
  // logo是否自适应宽度
  logoAutoSize: false,
  // 内容区域宽度是否铺满
  bodyFull: true,
  // 是否开启多标签
  showTabs: true,
  // 侧栏是否多彩图标
  colorfulIcon: false,

  // ==================================
  // TODO: 缓存配置国度
  // ==================================
  /**
   * @description token在Cookie中存储的天数，默认1天
   */
  cookieExpires: 1,
  // 用户信息存储的名称
  userStoreName: 'user',
  // token传递的header名称
  tokenHeaderName: 'Authorization',
  // token存储的名称
  tokenStoreName: 'Token'
}
