<!--客户圈-->
<template>
  <div>
    <el-dialog
        center
        title="客户"
        :visible="visible"
        :modal-append-to-body="false"
        custom-class="ele-dialog-form"
        width="60%"
        @update:visible="updateVisible">

      <!--列表-->
      <div v-if="step == 0">
        <el-form
            ref="form"
            :model="form"
            label-width="100px">

          <el-row :gutter="15">
            <el-col :span="6">
              <el-form-item label="客户名称:">
                <el-input
                    clearable
                    v-model="where.company_name"
                    placeholder="请输入"/>
              </el-form-item>
            </el-col>
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="合作关系:">-->
            <!--    <el-select-->
            <!--        clearable-->
            <!--        v-model="where.teamwork_status"-->
            <!--        placeholder="请选择"-->
            <!--        class="ele-fluid">-->
            <!--      <el-option label="申请中" :value="1"/>-->
            <!--      <el-option label="未合作" :value="0"/>-->
            <!--      <el-option label="已合作" :value="2"/>-->
            <!--    </el-select>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <!--<el-col :span="6">-->
            <!--  <el-form-item label="服务区域:">-->
            <!--    <el-cascader-->
            <!--        clearable-->
            <!--        ref="refCascader"-->
            <!--        :options="city"-->
            <!--        v-model="where.district"-->
            <!--    ></el-cascader>-->
            <!--  </el-form-item>-->
            <!--</el-col>-->
            <el-col :span="6">
              <el-button type="primary" @click="QueryList">查询</el-button>
            </el-col>
          </el-row>
        </el-form>

        <!--列表-->
        <div>
          <el-row :gutter="15">
            <el-col :span="8" v-for="(item,index) in List" :key="index" class="stylemargin-b">
              <div class="div1">
                <div class="div2">
                  {{item.name}}
                </div>
                <div style="text-align: right">
                  <el-button size="small" type="primary" @click="applyforcooperation()">申请开通</el-button>
                  <!--<el-button size="small" type="primary" @click="applyforcooperation()" v-if="item.status == 1"></el-button>-->
                  <!--<el-button size="small" type="primary" @click="applyforcooperation()" v-if="item.status == 2">申请开通</el-button>-->
                </div>
              </div>
            </el-col>
            <div v-if="List.length == 0">
              <el-empty description="暂无数据"></el-empty>
            </div>
          </el-row>
          <div class="margin8">
            <el-pagination
                style="text-align: center; margin-top: 20px"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-size="where.limit"
                :current-page="where.page"
                @size-change="ChangeSize"
                @current-change="currentChange"
                background>
            </el-pagination>
          </div>
        </div>
      </div>
      <!--<div slot="footer">-->
      <!--  <el-button-->
      <!--      @click="updateVisible(false)">取消-->
      <!--  </el-button>-->
      <!--  <el-button-->
      <!--      style="margin-left: 20px;"-->
      <!--      type="primary"-->
      <!--      :loading="loading"-->
      <!--      @click="save">确定-->
      <!--  </el-button>-->
      <!--</div>-->
    </el-dialog>

    <el-dialog
        title="申请开通"
        :visible="cooperation"
        :modal-append-to-body="false"
        custom-class="ele-dialog-form"
        :before-close="handleClose"
        width="40%">
     <div>
       <el-row :gutter="15" class="margin4">
         <el-col :span="3">
           客户：
         </el-col>
         <el-col :span="21">
           平安保险
         </el-col>
       </el-row>
       <el-row :gutter="15" class="margin4">
         <el-col :span="3">
           内容：
         </el-col>
         <el-col :span="21">
           <el-input
               type="textarea"
               clearable
               :autosize="{ minRows: 3, maxRows: 3}"
               placeholder="请输入内容"
               v-model="remark">
           </el-input>
         </el-col>
       </el-row>
       <div style="text-align: right;margin-top: 10px;">
         <el-button @click="handleClose">取消</el-button>
         <el-button type="primary" @click="confirm">确定</el-button>
       </div>
     </div>
    </el-dialog>

  </div>
</template>

<script>
//获取服务区域城市
import {apply_cooperation, getcityList, getget_facilitator} from '@/api/yunli'
export default {
  props: {
    // 弹窗是否打开
    visible: Boolean,
  },
  data(){
    return {
      form:{},
      loading:false,

      //城市列表
      city:[],

      // 表格搜索条件
      where: {
        page: 1, // 当前页数
        limit: 10 // 每页显示条目个数
      },
      // 总条目数
      total: 0,
      List:[
        {
          name:'平安保险',
          status:0
        },
        {
          name:'国寿财保险',
          status:1
        },
        {
          name:'中华联合保险',
          status:2
        }
      ],

      activeName:'first',
      form1:{},

      step:0,
      selectdata:{},

      cooperation:false,

      remark:'',

    }
  },

  mounted() {
    //调用获取城市列表
    this.getCity();
    //获取服务商列表
    this.getList();
  },

  methods:{
    // 获取城市列表
    getCity(){
      getcityList().then(res => {
        // console.log(res);
        this.city = res.data;
      })
    },

    //获取列表
    getList(){
      // getget_facilitator(this.where).then(res => {
      //   this.total = res.data.count;
      //   this.List = res.data.list;
      // })
    },
    //点击查询
    QueryList(){
      this.getList();
    },

    //级联选择器
    // handleChange(value) {
    //   const areaTextArr = [];
    //   const arr = this.$refs["refCascader"].getCheckedNodes()[0].pathNodes;
    //   arr.forEach((i) => {
    //     areaTextArr.push(i.label);
    //   });
    //   // console.log(areaTextArr);
    //   // console.log(value);
    //   let area = [];
    //   let province = value[0] + '|' + areaTextArr[0];
    //   let city = value[1] + '|' + areaTextArr[1];
    //   let district = value[2] + '|' + areaTextArr[2];
    //   area.push(province,city,district);
    //   console.log(area)
    // },

    // 当前页数改变事件
    currentChange(val) {
      console.log("当前页数", val)
      this.where.page = val;
      this.getList();
    },
    ChangeSize(val){
      console.log('当前页面显示的条数',val);
      this.where.limit = val;
      this.getList();
    },

    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value);
    },

    save(){
      this.$emit('update:visible', false);
    },

    // 点击切换
    handleClick(tab, event) {
      console.log(tab, event);
    },

    // 点击未合作 或者已合作
    applyfor(item,index){
      console.log(item);
      this.step = 1;
      this.selectdata = item;
    },

    // 点击申请合作
    applyforcooperation(){
      this.cooperation = true;
      // this.visible = false;
    },

    // 申请合作取消按钮
    handleClose(){
      this.cooperation = false;
      // this.visible = true;
    },

    //点击确定
    confirm(){
      let data = {
        remark: this.remark
      }
      apply_cooperation(this.selectdata.id,data).then(res => {
        if(res.code == 200){
          this.cooperation = false;
          this.visible = true;
          this.step = 0;
          this.$message.success(res.msg);
        }else {
          this.$message.error(res.msg);
        }
      })
    },

  }

}
</script>

<style scoped lang="scss">
.stylemargin-b{
  margin-bottom: 10px;
}
.div1{
  border: 1px solid #e6e6e8;border-radius: 6px;
}
.div2{
  display: flex;align-items: center;padding: 20px;
  font-size: 18px;font-weight: 500;color: #707070;margin-left: 10px;
}
.img1{
  width: 40px;height: 40px;border-radius: 50%;
}
.div3{
  background: #F8F9FB;padding: 10px 20px 10px 20px;display: flex;align-items: center;justify-content: space-between;
}
.margin1{
  margin-left: 10px;
}
.margin2{
  margin-top: 15px;
}
.margin3{
  margin-top: 30px;
}
.img2{
  width: 80px;height: 80px;border-radius: 50%;
}
.div4{
  text-align: center;margin-top: 60px;margin-bottom: 60px;
}
.margin4{
  margin-bottom: 20px;
}
.div5{
  background: #F8F9FB;padding: 20px;
}
.margin8{
  margin-bottom: 20px;
}
.margin10{
  margin-bottom: 15px;
}
</style>
