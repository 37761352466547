import PageHeader from './page-header'
import IconTools from './icon-tools'
import UsersLabel from './users-label'
import SendCoupone from './send-coupons'
import LinkAddress from './linkaddress'
import UploadPictures from './upload-pictures'
import CustomForm from './custom-form'
import WangEditor from './wang-editor'
import UploadVideo from './upload-video'
import UploadImg from './upload-img'
import Logos from './logo'

const ViewUI = {
  PageHeader,
  IconTools,
  UsersLabel,
  SendCoupone,
  LinkAddress,
  UploadPictures,
  CustomForm,
  WangEditor,
  UploadVideo,
  UploadImg,
  Logos
}

export default {
  install: (app, opt) => {
    // 注册全局组件
    Object.keys(ViewUI).forEach(key => {
      app.component(key, ViewUI[key])
    })
  }
}
