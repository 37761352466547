import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/)

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  plugins: [
    // 这个就是存入localStorage的值
    new VuexPersistence({
      reducer: (state) => ({
        app: state.app,
        menus: state.menus,
        menu: state.menu,
        layout: state.layout,
        themeConfig: state.themeConfig,
        routesList: state.routesList,
        keepAliveNames: state.keepAliveNames
      }),
      storage: window.localStorage
    }).plugin
  ],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules,
  getters
})
