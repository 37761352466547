<template>
  <div v-if="FromData">
    <Modal
      v-model="modals"
      scrollable
      footer-hide
      closable
      :title="FromData.title"
      :z-index="1"
      width="700"
      @on-cancel="cancel">
      <template>
        <div class="radio acea-row row-middle" v-if="FromData.action === '/marketing/coupon/save.html'">
          <div class="name ivu-form-item-content">优惠券类型</div>
          <Radio-group v-model="type" @on-change="couponsType">
            <Radio :label="0">通用券</Radio>
            <Radio :label="1">品类券</Radio>
            <Radio :label="2">商品券</Radio>
          </Radio-group>
        </div>
      </template>
      <!-- <form-create :option="config" :rule="Array.from(FromData.rules)" @submit="onSubmit" class="formBox" ref="fc" handleIcon="false"></form-create> -->
    </Modal>
  </div>
</template>

<script>
// import formCreate from '@form-create/iview'
// import request from '@/utils/request'
export default {
  name: 'CustomForm',
  components: {
    // formCreate: formCreate.$form()
  },
  props: {
    FromData: {
      type: Object,
      default: null
    },
    update: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      modals: false,
      type: 0,
      loading: false,
      config: {
        global: {
          upload: {
            props: {
              onSuccess (res, file) {
                if (res.code === 200) {
                  file.url = res.data.src
                } else {
                  this.Message.error(res.msg)
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    couponsType () {
      this.$parent.addType(this.type)
    },
    // 提交表单 group
    onSubmit (formData) {
      let datas = {}
      datas = formData
      if (this.loading) return
      this.loading = true
      request({
        url: this.FromData.action,
        method: this.FromData.method,
        data: datas
      }).then((res) => {
        if (this.update) this.$emit('submitFail')
        // if (this.update) this.$parent.getList()
        this.$Message.success(res.msg)
        this.modals = false
        setTimeout(() => {
          this.loading = false
        }, 1000)
      }).catch((err) => {
        this.loading = false
        this.$Message.error(err.msg)
      })
    },
    // 关闭按钮
    cancel () {
      this.type = 0
    }
  }
}
</script>

<style scoped lang="scss">
.v-transfer-dom >>> .ivu-modal-content-drag {
  z-index: 2 !important;
}

.radio {
  margin-bottom: 14px;
}

.radio >>> .name {
  width: 125px;
  text-align: right;
  padding-right: 12px;
}
</style>
