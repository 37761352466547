<!--高德地图组件-->
<template>
  <div>
    <div id="container" :style="{height:msgData + `px`}"></div>
    <div id="panel" style="display: none;"></div>
    <p id='adcode'></p>
  </div>
</template>

<script>
  // 引入高德地图
  import AMapLoader from '@amap/amap-jsapi-loader';
  //获取高德地图的key
  import setting from '@/config/config'

  export default {
    props:{
      msgData: Number,
      // 起点
      locationorigin: Array,
      // 终点
      locationdestination: Array,
      // 司机坐标
      driver: Array,
      // 实际规划
      coord:Array
    },
    data(){
      return{
        //此处不声明 map 对象，可以直接使用 this.map赋值或者采用非响应式的普通对象来存储。
        map:null,
        // 起点
        // locationorigindata:[36.35991,114.93882],
        // 终点
        // locationdestinationdata:[36.359785,114.937999],
      }
    },

    mounted() {
      //接收父组件传递的值 地图容器的高度
      console.log(this.msgData);

      console.log(this.locationorigin);
      console.log(this.locationdestination);

      console.log(this.coord,'coord')

      // 高德地图
      window._AMapSecurityConfig = {
        securityJsCode: setting.secretkey,
      }

      //DOM初始化完成进行地图初始化
      this.initMap();
    },

    methods:{
      // 初始化高德地图
      initMap(){
        // AMapLoader.load({
        //   key:"5ac2da41c4146f58f72ee17dcade366a",// 申请好的Web端开发者Key，首次调用 load 时必填
        //   version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        //   plugins:[''],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        // }).then((AMap)=>{
        //   this.map = new AMap.Map("container",{  //设置地图容器id
        //     viewMode:"3D",    //是否为3D地图模式
        //     zoom:15,           //初始化地图级别
        //     // center:[12709275.141845375,2549904.9028324387], //初始化地图中心点位置
        //     mapStyle: "amap://styles/whitesmoke",
        //     resizeEnable: true
        //   });
        //   let that = this;
        //   AMap.plugin('AMap.Geolocation', function() {
        //     var geolocation = new AMap.Geolocation({
        //       enableHighAccuracy: true,//是否使用高精度定位，默认:true
        //       timeout: 10000,          //超过10秒后停止定位，默认：无穷大
        //       maximumAge: 0,           //定位结果缓存0毫秒，默认：0
        //       convert: true,           //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
        //       showButton: true,        //显示定位按钮，默认：true
        //       buttonPosition: 'LB',    //定位按钮停靠位置，默认：'LB'，左下角
        //       buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
        //       showMarker: true,        //定位成功后在定位到的位置显示点标记，默认：true
        //       showCircle: true,        //定位成功后用圆圈表示定位精度范围，默认：true
        //       panToLocation: true,     //定位成功后将定位到的位置作为地图中心点，默认：true
        //       zoomToAccuracy:true      //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
        //     });
        //     that.map.addControl(geolocation);
        //     geolocation.getCurrentPosition(function (status, result) {
        //       if (status == 'complete') {
        //         console.log(result)
        //       } else {
        //         console.log(result)
        //       }
        //     })
        //   });
        // }).catch(e=>{
        //   console.log(e);
        // })


        AMapLoader.load({
          key: setting.key, // 申请好的Web端开发者Key，首次调用 load 时必填
          version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
          plugins: ['AMap.Driving','AMap.Geocoder','AMap.Router'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
        }).then((AMap) => {
          console.log(this.locationorigin);
          this.map = new AMap.Map("container", {
            // 设置地图容器id
            mapStyle: "amap://styles/whitesmoke",
            zoom: 13,
            center: [116.397428, 39.90923],//地图中心点
            resizeEnable: true
          });

          // 有起点和终点则绘制行车路线
          if(this.locationorigin.length != 0 && this.locationdestination.length != 0){
            //构造路线导航类
            var driving = new AMap.Driving({
              map: this.map,
              panel: "panel"
            });
            console.log(this.locationorigin);
            console.log(this.locationdestination);
            driving.search(new AMap.LngLat(this.locationorigin[0], this.locationorigin[1]), new AMap.LngLat(this.locationdestination[0], this.locationdestination[1]), function(status, result) {
              // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
              if (status === 'complete') {
                console.log('绘制驾车路线完成')
              } else {
                console.log('获取驾车数据失败：' + result)
              }
            });
          }else if(this.locationdestination.length != 0){
            // 否则只渲染一个终点的标志
            var marker = new AMap.Marker({
              map: this.map,
              icon: new AMap.Icon({
                image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',
                imageSize: new AMap.Size(30, 41),
              }),
              position: [this.locationdestination[0], this.locationdestination[1]],
              offset: new AMap.Pixel(-13, -30),
            });
            // 添加点标记
            marker.setMap(this.map);
            var center = this.map.getCenter();
            // var setFitViewBtn = document.getElementById('setFitView');
            this.map.setFitView(null, false, [150, 60, 100, 60]);
            var newCenter = this.map.getCenter();
          }

          // 申请实际规划路线
          if(this.coord.length > 0){
            // 获取起点经纬度
            let last = this.coord[0].slice(0)[0];
            let start = {
              lng: last[0],
              lat: last[1],
            }
            //起点
            var marker = new AMap.Marker({
              map: this.map,
              icon: new AMap.Icon({
                image: 'https://webapi.amap.com/theme/v1.3/markers/n/start.png',
                imageSize: new AMap.Size(30, 41),
              }),
              position: [start.lng, start.lat],
              offset: new AMap.Pixel(-13, -30),
            });
            // // 添加点标记
            marker.setMap(this.map);

            // 获取终点
            let len = this.coord.length
            let len1 = this.coord[len-1];
            let len2 = len1.length;
            let len3 = len1[len2-1];
            let start1 = {
              lng: len3[0],
              lat: len3[1],
            }
            //起点
            var marker1 = new AMap.Marker({
              map: this.map,
              icon: new AMap.Icon({
                image: 'https://webapi.amap.com/theme/v1.3/markers/n/end.png',
                imageSize: new AMap.Size(30, 41),
              }),
              position: [start1.lng, start1.lat],
              offset: new AMap.Pixel(-13, -30),
            });
            // // 添加点标记
            marker1.setMap(this.map);

            var polyline2 = new AMap.Polyline({
              path: this.coord,
              isOutline: true,
              outlineColor: '#ffeeff',
              borderWeight: 3,
              strokeColor: "#3366FF",
              strokeOpacity: 1,
              strokeWeight: 6,
              // 折线样式还支持 'dashed'
              strokeStyle: "dashed",
              // strokeStyle是dashed时有效
              strokeDasharray: [15, 5],
              lineJoin: 'round',
              lineCap: 'round',
              zIndex: 50,
            });
            this.map.add([polyline2]);
            this.map.setFitView(null, false, [150, 60, 100, 60]);
          }

        }).catch((e) => {
          console.log(e);
        });

      },



  }

  }
</script>

<style lang="scss" scoped>
  #container{
    padding:0px;
    margin: 0px;
    width: 100%;
    //min-height: 300px;
    //max-height: 750px;
  }
</style>
