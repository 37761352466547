import {
  getBreadCrumbList,
  setTagNavListInLocalstorage,
  getTagNavListFromLocalstorage,
  routeHasExist,
  getRouteTitleHandled,
  getHomeRoute,
  routeEqual,
  getNextRoute
} from '@/utils'
import router from '@/router'
import config from '@/config'
const { homeName } = config

export default {
  namespaced: true,
  state: {
    adminTitle: '一键道路救援',
    // 面包屑数据列表
    breadCrumbList: [],
    // Tabs 数据列表
    tagNavList: [],

    homeRoute: {}
  },
  mutations: {
    // 添加标签
    addTag: (state, { route, type = 'unshift' }) => {
      let router = getRouteTitleHandled(route)
      if (!routeHasExist(state.tagNavList, router)) {
        if (type === 'push') state.tagNavList.push(router)
        else {
          if (router.name === homeName) state.tagNavList.unshift(router)
          else state.tagNavList.splice(1, 0, router)
        }
        setTagNavListInLocalstorage([...state.tagNavList])
      }
    },
    // 设置面包屑
    setBreadCrumb: (state, route) => {
      state.breadCrumbList = getBreadCrumbList(route, state.homeRoute)
    },
    // 设置标签页
    setTagNavList: (state, list) => {
      console.log(list)
      let tagList = []
      if (list) {
        tagList = [...list]
      } else tagList = getTagNavListFromLocalstorage() || []
      if (tagList[0] && tagList[0].name !== homeName) tagList.shift()
      let homeTagIndex = tagList.findIndex((item) => item.name === homeName)
      if (homeTagIndex > 0) {
        let homeTag = tagList.splice(homeTagIndex, 1)[0]
        tagList.unshift(homeTag)
      }
      state.tagNavList = tagList
      setTagNavListInLocalstorage([...tagList])
    },
    // 设置首页路由
    setHomeRoute: (state, routes) => {
      state.homeRoute = getHomeRoute(routes, homeName)
    },
    closeTag: (state, route) => {
      let tag = state.tagNavList.filter((item) => routeEqual(item, route))
      route = tag[0] ? tag[0] : null
      if (!route) return
      closePage(state, route)
    }
  },
  actions: {
  },
  modules: {
  }
}

const closePage = (state, route) => {
  const nextRoute = getNextRoute(state.tagNavList, route)
  state.tagNavList = state.tagNavList.filter((item) => {
    return !routeEqual(item, route)
  })
  router.push(nextRoute)
}
