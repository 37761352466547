import request from '@/utils/request'

/**
 * 运力 自营司机列表
 * @returns {*}
 */
export function getself_driver (params) {
    return request({
        url: 'capacity/self_drivers',
        method: 'get',
        params
    })
}

/**
 * 运力 自营司机添加
 * @returns {*}
 */
export function Addself_driver (data) {
  return request({
    url: `capacity/self_drivers`,
    method: 'post',
    data
  })
}

/**
 * 运力 自营司机详情
 * @returns {*}
 */
export function Detalsself_driver (id,params) {
  return request({
    url: `capacity/self_drivers/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运力 自营司机详情 账户明细
 * @returns {*}
 */
export function getparticulars (id,params) {
  return request({
    url: `capacity/self_driver/particulars/${id}`,
    method: 'get',
    params
  })
}

/**
 *  运力 自营司机编辑
 * @returns {*}
 */
export function Edutself_driver (id,data) {
  return request({
    url: `capacity/self_drivers/${id}`,
    method: 'put',
    data
  })
}

/**
 *  运力 自营司机 删除
 * @returns {*}
 */
export function Delself_driver (id) {
  return request({
    url: `capacity/self_drivers/${id}`,
    method: 'delete',
  })
}

/**
 * 运力 自营司机 所有车辆接口
 * @returns {*}
 */
export function getsome_facilitator_vehicle (params) {
  return request({
    url: `vehicle/vehicle/some_facilitator_vehicle`,
    method: 'get',
    params
  })
}
/**
 * 运力 自营司机 获取合作服务商下拉列表接口
 * @returns {*}
 */
export function facilitatorselect (params) {
  return request({
    url: 'capacity/facilitator/select',
    method: 'get',
    params
  })
}

/**
 * 运力 自营司机 获取服务商列表(包含自己)接口
 * @returns {*}
 */
export function commonfacilitator (params) {
  return request({
    url: 'common/facilitator',
    method: 'get',
    params
  })
}

/**
 * 运力 自营司机 薪酬列表
 * @returns {*}
 */
export function getemolument_list (params) {
  return request({
    url: 'capacity/self_driver/emolument_list',
    method: 'get',
    params
  })
}

/**
 * 运力 员工管理 薪酬下拉
 * @returns {*}
 */
export function some_facilitator_remuneration (params) {
  return request({
    url: 'manpower/remuneration/some_facilitator_remuneration',
    method: 'get',
    params
  })
}

/**
 * 运力 自营司机 创建薪酬
 * @returns {*}
 */
export function Addemolument_save (data) {
  return request({
    url: `capacity/self_driver/emolument_save`,
    method: 'post',
    data
  })
}

/**
 * 运力 自营司机 服务类型
 * @returns {*}
 */
export function getservice_type (params) {
  return request({
    url: 'system/services_available/get_checkbox_list',
    method: 'get',
    params
  })
}


/**
 * 运力 待认证共享列表
 * @returns {*}
 */
export function getcheck_list (params) {
  return request({
    url: 'capacity/shared_driver/check_list',
    method: 'get',
    params
  })
}
/**
 * 运力 待认证共享列表 审核
 * @returns {*}
 */
export function share_drivershare_check (id,data) {
  return request({
    url: `capacity/shared_driver/shared_check/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运力 待认证共享列表 价格信息审核
 * @returns {*}
 */
export function price_check (id,data) {
  return request({
    url: `capacity/shared_driver/price_check/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运力 共享司机列表
 * @returns {*}
 */
export function getshare_drivers (params) {
  return request({
    url: 'capacity/shared_driver/list',
    method: 'get',
    params
  })
}
/**
 * 运力 共享司机详情
 * @returns {*}
 */
export function Detalisshare_driver (id,params) {
  return request({
    url: `capacity/shared_driver/details/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运力 共享车辆详情
 * @returns {*}
 */
export function Drice_details (id) {
  return request({
    url: `/capacity/shared_driver/check_price_details/${id}`,
    method: 'get',
  })
}

/**
 * 运力 共享价格详情
 * @returns {*}
 */
export function Driver_details (id) {
  return request({
    url: `/capacity/shared_driver/check_details/${id}`,
    method: 'get',
  })
}



/**
 * 运力 共享车辆认证详情
 * @returns {*}
 */
export function Vehicle_certification (id) {
  return request({
    url: `/capacity/shared_driver/check_vehicle_details/${id}`,
    method: 'get',
  })
}
///capacity/shared_driver/check_vehicle_details/{id}

/**
 * 运力 共享车辆认证审核
 * @returns {*}
 */
export function Vehicle_certification_renzhen (id,data) {
  return request({
    url: `/capacity/shared_driver/vehicle_check/${id}`,
    data,
    method: 'POST',
  })
}

///capacity/shared_driver/price_check/{id}

// /capacity/shared_driver/price_check/{id}

// /capacity/shared_driver/price_check/{id}

/**
 * 运力 共享司机详情 账户明细
 * @returns {*}
 */
export function shared_driverparticulars (id,type,params) {
  return request({
    url: `capacity/shared_driver/particulars/${id}/${type}`,
    method: 'get',
    params
  })
}

/**
 * 运力 待认证共享认证审核司机列表
 * @returns {*}
 */
export function check_details (id,params) {
  return request({
    url: `capacity/shared_driver/check_details/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运力 共享司机启用OR停用接口
 * @returns {*}
 */
export function set_stop (id,params) {
  return request({
    url: `capacity/shared_driver/set_stop/${id}`,
    method: 'get',
    params
  })
}

/**
 *  运力 共享司机删除接口
 * @returns {*}
 */
export function Delshare_drivers (id) {
  return request({
    url: `capacity/shared_driver/delete/${id}`,
    method: 'delete',
  })
}

/**
 * 运力 合作司机
 * @returns {*}
 */
export function getoutsource_driver (params) {
  return request({
    url: 'capacity/outsource_drivers',
    method: 'get',
    params
  })
}

/**
 * 运力 合作司机添加
 * @returns {*}
 */
export function Addoutsource_driver (data) {
  return request({
    url: `capacity/outsource_drivers`,
    method: 'post',
    data
  })
}
/**
 *  运力 合作司机修改
 * @returns {*}
 */
export function Editoutsource_driver (id,data) {
  return request({
    url: `capacity/outsource_drivers/${id}`,
    method: 'put',
    data
  })
}

/**
 * 运力 合作司机详情
 * @returns {*}
 */
export function Detailsoutsource_drivers (id,params) {
  return request({
    url: `capacity/outsource_drivers/${id}`,
    method: 'get',
    params
  })
}

/**
 *  运力 合作司机删除
 * @returns {*}
 */
export function Deloutsource_driver (id) {
  return request({
    url: `capacity/outsource_drivers/${id}`,
    method: 'delete',
  })
}


/**
 * 运力 外协司机
 * @returns {*}
 */
export function clone_drivers (params) {
  return request({
    url: 'capacity/clone_drivers',
    method: 'get',
    params
  })
}
/**
 * 运力 外协司机添加
 * @returns {*}
 */
export function Addclone_drivers (data) {
  return request({
    url: `capacity/clone_drivers`,
    method: 'post',
    data
  })
}
/**
 *  运力 外协司机修改
 * @returns {*}
 */
export function Editclone_drivers (id,data) {
  return request({
    url: `capacity/clone_drivers/${id}`,
    method: 'put',
    data
  })
}
/**
 *  运力 外协司机删除
 * @returns {*}
 */
export function Delclone_drivers (id) {
  return request({
    url: `capacity/clone_drivers/${id}`,
    method: 'delete',
  })
}
/**
 * 运力 外协司机详情
 * @returns {*}
 */
export function Detailsclone_drivers (id,params) {
  return request({
    url: `capacity/clone_drivers/${id}`,
    method: 'get',
    params
  })
}


/**
 * 运力 服务商列表
 * @returns {*}
 */
export function getfacilitators (params) {
  return request({
    url: 'facilitator/all_list',
    method: 'get',
    params
  })
}

/**
 *  运力 服务商 重置密码
 * @returns {*}
 */
export function reset_password (id,data) {
  return request({
    url: `facilitator/reset_password/${id}`,
    method: 'post',
    data
  })
}

/**
 *  运力 服务商 进入后台
 * @returns {*}
 */
export function to_admin (id) {
  return request({
    url: `facilitator/to_admin/${id}`,
    method: 'get',
  })
}

/**
 * 运力 服务商状态修改
 * @returns {*}
 */
export function Editstatus (id) {
  return request({
    url: `facilitator/status/${id}`,
    method: 'get',
    // params
  })
}

/**
 *  运力 服务商 删除
 * @returns {*}
 */
export function Delfacilitators (id) {
  return request({
    url: `capacity/facilitators/${id}`,
    method: 'delete',
  })
}
/**
 * 运力 服务商 添加
 * @returns {*}
 */
export function Addfacilitators (data) {
  return request({
    url: `capacity/facilitators`,
    method: 'post',
    data
  })
}
/**
 *  运力 服务商 修改
 * @returns {*}
 */
export function Editfacilitators (id,data) {
  return request({
    url: `facilitator/update/${id}`,
    method: 'put',
    data
  })
}

/**
 *  运力 服务商与服务商 修改
 * @returns {*}
 */
export function update_profile (id,data) {
  return request({
    url: `capacity/facilitator/update_profile/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运力 服务商 详情
 * @returns {*}
 */
export function Detailsfacilitators (id,params) {
  return request({
    url: `/facilitator/details/${id}`,
    method: 'get',
    params
  })
}


/**
 * 运力 服务商 详情 计价公式 获取列表
 * @returns {*}
 */
export function gettaximeters (id,params) {
  return request({
    url: `operation/taximeter/facilitator/${id}/list`,
    method: 'get',
    params
  })
}

/**
 * 运力 服务商 详情 计价公式 获取详情
 * @returns {*}
 */
export function Detailstaximeters (id,id1,params) {
  return request({
    url: `operation/taximeter/facilitator/${id}/info/${id1}`,
    method: 'get',
    params
  })
}

/**
 * 运力 服务商 详情 计价公式 删除
 * @returns {*}
 */
export function Deltaximeter (id,id1) {
  return request({
    url: `operation/taximeter/facilitator/${id}/delete/${id1}`,
    method: 'delete',
  })
}

/**
 * 运力 服务商 详情 计价公式 获取配置
 * @returns {*}
 */
export function taximeterget_config (params) {
  return request({
    url: `operation/taximeter/get_config`,
    method: 'get',
    params
  })
}
/**
 * 运力 服务商 详情 计价公式 添加计价
 * @returns {*}
 */
export function Addtaximeters (id,data) {
  return request({
    url: `operation/taximeter/facilitator/${id}/save`,
    method: 'post',
    data
  })
}

/**
 * 运力 服务商 详情 计价公式 修改计价
 * @returns {*}
 */
export function Edittaximeters (id,id1,data) {
  return request({
    url: `operation/taximeter/facilitator/${id}/put/${id1}`,
    method: 'put',
    data
  })
}

/**
 * 运力 服务商 详情 计价公式 服务区域
 * @returns {*}
 */
export function taximeterget_city (params) {
  return request({
    url: `operation/taximeter/get_city`,
    method: 'get',
    params
  })
}


/**
 * 运力 服务商 详情 计价公式 获取列表
 * @returns {*}
 */
export function allgettaximeters (id,params) {
  return request({
    url: `operation/taximeter/facilitator/${id}/all_list`,
    method: 'get',
    params
  })
}

/**
 * 运力 服务商 详情 计价公式 获取详情
 * @returns {*}
 */
export function allDetailstaximeters (id,id1,params) {
  return request({
    url: `operation/taximeter/facilitator/${id}/all_info/${id1}`,
    method: 'get',
    params
  })
}

/**
 * 运力 服务商 详情 计价公式 删除
 * @returns {*}
 */
export function allDeltaximeter (id,id1) {
  return request({
    url: `operation/taximeter/facilitator/${id}/all_delete/${id1}`,
    method: 'delete',
  })
}
/**
 * 运力 服务商 详情 计价公式 添加计价
 * @returns {*}
 */
export function allAddtaximeters (id,data) {
  return request({
    url: `operation/taximeter/facilitator/${id}/all_save`,
    method: 'post',
    data
  })
}

/**
 * 运力 服务商 详情 计价公式 修改计价
 * @returns {*}
 */
export function allEdittaximeters (id,id1,data) {
  return request({
    url: `operation/taximeter/facilitator/${id}/all_put/${id1}`,
    method: 'put',
    data
  })
}


/**
 * 运力 待认证列表
 * @returns {*}
 */
export function getfacilitator (params) {
  return request({
    url: 'facilitator/approve',
    method: 'get',
    params
  })
}

/**
 * 运力 服务商 认证审核
 * @returns {*}
 */
export function auditcheck (id,data) {
  return request({
    url: `facilitator/check/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运力 服务商 认证审核 v2
 * @returns {*}
 */
export function auditcheckv (id,data) {
  return request({
    url: `v2/facilitator/check/${id}`,
    method: 'post',
    data
  })
}



// 公共
/**
 * 城市列表接口
 * @returns {*}
 */
export function getcityList (params) {
  return request({
    url: 'city_list',
    method: 'get',
    params
  })
}


/**
 * 城市列表接口
 * @returns {*}
 */
export function getget_services_available (params) {
  return request({
    url: 'common/get_services_available',
    method: 'get',
    params
  })
}


/**
 * 获取合作服务商 商圈
 * @returns {*}
 */
export function getget_facilitator (params) {
  return request({
    url: 'capacity/facilitator/get_facilitator',
    method: 'get',
    params
  })
}
/**
 * 服务商商圈申请接口
 * @returns {*}
 */
export function apply_cooperation (id,data) {
  return request({
    url: `capacity/facilitator/apply_cooperation/${id}`,
    method: 'post',
    data
  })
}


/**
 * 服务商详情 企业钱包基本信息
 * @returns {*}
 */
export function firm_wallet (id,params) {
  return request({
    url: `facilitator/details_purse//${id}`,
    method: 'get',
    params
  })
}
/**
 * 服务商详情 企业钱包收支明细
 * @returns {*}
 */
export function balance_payments (id,params) {
  return request({
    url: `facilitator/balance_payments/${id}`,
    method: 'get',
    params
  })
}
/**
 * 服务商详情 企业钱包开票记录接口
 * @returns {*}
 */
export function invoice_log (id,params) {
  return request({
    url: `facilitator/invoice_log/${id}`,
    method: 'get',
    params
  })
}
/**
 * 服务商详情 企业钱包提现记录接口
 * @returns {*}
 */
export function extract_log (id,params) {
  return request({
    url: `capacity/facilitator/extract_log/${id}`,
    method: 'get',
    params
  })
}


/**
 * 服务商详情 获取会员信息接口
 * @returns {*}
 */
export function member_info (id,params) {
  return request({
    url: `/facilitator/details_vip/${id}`,
    method: 'get',
    params
  })
}


/**
 * 服务商单量统计接口
 * @returns {*}
 */
export function otheroutput_statistics (params) {
  return request({
    url: 'capacity/other/output_statistics',
    method: 'get',
    params
  })
}


/**
 * 服务商详情 对账资料 开票资料 列表
 * @returns {*}
 */
export function getinvoice (id,params) {
  return request({
    url: `capacity/facilitator/invoice/${id}`,
    method: 'get',
    params
  })
}
/**
 * 服务商详情 对账资料 开票资料新增
 * @returns {*}
 */
export function save_invoice (id,data) {
  return request({
    url: `capacity/facilitator/invoice/${id}`,
    method: 'post',
    data
  })
}

/**
 *  服务商详情 对账资料 开票资料删除
 * @returns {*}
 */
export function Deldel_invoice (id,params) {
  return request({
    url: `capacity/facilitator/invoice/${id}`,
    method: 'delete',
    params
  })
}

/**
 * 服务商详情 对账资料 收款资料 列表
 * @returns {*}
 */
export function getproceeds (id,params) {
  return request({
    url: `capacity/facilitator/collection/${id}`,
    method: 'get',
    params
  })
}

/**
 * 服务商详情 对账资料 收款资料 添加
 * @returns {*}
 */
export function Addproceeds (id,data) {
  return request({
    url: `capacity/facilitator/collection/${id}`,
    method: 'post',
    data
  })
}
/**
 * 服务商详情 对账资料 收款资料 修改
 * @returns {*}
 */
export function Editproceeds (id,data) {
  return request({
    url: `operation/reconciliation/proceeds/${id}`,
    method: 'put',
    data
  })
}
/**
 * 服务商详情 对账资料 收款资料 删除
 * @returns {*}
 */
export function Delproceeds (id,params) {
  return request({
    url: `capacity/facilitator/collection/${id}`,
    method: 'delete',
    params
  })
}

/**
 * 运力 服务商与服务商合同信息 签约模式修改接口
 * @returns {*}
 */
export function put_contract_award (id,data) {
  return request({
    url: `capacity/facilitator/put_contract_award/${id}`,
    method: 'post',
    data
  })
}



/**
 * 服务商与服务商列表
 * @returns {*}
 */
export function otherfacilitator_and_facilitator (params) {
  return request({
    url: 'capacity/other/facilitator_and_facilitator',
    method: 'get',
    params
  })
}
/**
 * 运力 服务商与服务商新增接口
 * @returns {*}
 */
export function Addsave_facilitator (data) {
  return request({
    url: `capacity/other/save_facilitator`,
    method: 'post',
    data
  })
}
/**
 * 运力 获取服务商与服务商基本信息接口
 * @returns {*}
 */
export function facilitatorsecond_party_info (id, params) {
  return request({
    url: `capacity/facilitator/second_party_info/${id}`,
    method: 'get',
    params
  })
}

/**
 * 运力 获取服务商申请记录接口
 * @returns {*}
 */
export function otherapplication_record (params) {
  return request({
    url: 'capacity/other/resource_list',
    method: 'get',
    params
  })
}

/**
 * 运力 服务商 服务商申请记录上传汇款单接口
 * @returns {*}
 */
export function application_serial_number (id,data) {
  return request({
    url: `capacity/other/application_serial_number/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运力 服务商 服务商申请记录上传发票接口
 * @returns {*}
 */
export function application_invoice (id,data) {
  return request({
    url: `capacity/other/application_invoice/${id}`,
    method: 'post',
    data
  })
}

/**
 * 运力 获取区域运力统计
 * @returns {*}
 */
export function get_range_statistics (params) {
  return request({
    url: 'capacity/get_range_statistics',
    method: 'get',
    params
  })
}
/**
 * 运力 获取区域运力统计服务商下的司机
 * @returns {*}
 */
export function get_driver_coord (params) {
  return request({
    url: `capacity/get_driver_coord`,
    method: 'get',
    params
  })
}

/**
 * 运力 获取城市二级
 * @returns {*}
 */
export function second_level_city (params) {
  return request({
    url: `common/second_level_city`,
    method: 'get',
    params
  })
}

/**
 * 运力 获取全国 服务商 司机
 * @returns {*}
 */
export function nationwide_facilitator (params) {
  return request({
    url: `capacity/nationwide_facilitator`,
    method: 'get',
    params
  })
}


/**
 * 运力 大屏 【服务商大屏】置换机构码
 * @returns {*}
 */
export function replacement (params) {
  return request({
    url: `common/replacement`,
    method: 'get',
    params
  })
}
/**
 * 运力 大屏 服务项目占比
 * @returns {*}
 */
export function service_ratioyj (type,params) {
  return request({
    url: `facilitator_screen/service_ratio/${type}`,
    method: 'get',
    params
  })
}
/**
 * 运力 大屏 销售总额
 * @returns {*}
 */
export function total_amount (type,params) {
  return request({
    url: `facilitator_screen/total_amount/${type}`,
    method: 'get',
    params
  })
}
/**
 * 运力 大屏 前五月销售量接口
 * @returns {*}
 */
export function quarteryj (type,params) {
  return request({
    url: `facilitator_screen/quarter/${type}`,
    method: 'get',
    params
  })
}
/**
 * 运力 大屏 前五城市销售量接口
 * @returns {*}
 */
export function urban_marketing (type,params) {
  return request({
    url: `facilitator_screen/urban_marketing/${type}`,
    method: 'get',
    params
  })
}
/**
 * 运力 大屏 服务销售排名接口
 * @returns {*}
 */
export function sales_rank (type,params) {
  return request({
    url: `facilitator_screen/sales_rank/${type}`,
    method: 'get',
    params
  })
}
/**
 * 运力 大屏 同比销售额接口
 * @returns {*}
 */
export function quarter_sale (type,params) {
  return request({
    url: `facilitator_screen/quarter_sale/${type}`,
    method: 'get',
    params
  })
}
/**
 * 运力 大屏 雷达接口
 * @returns {*}
 */
export function radar (type,params) {
  return request({
    url: `facilitator_screen/radar/${type}`,
    method: 'get',
    params
  })
}

/**
 * 运力 虚拟定位 资源列表
 * @returns {*}
 */
export function virtuals (params) {
  return request({
    url: `capacity/virtuals`,
    method: 'get',
    params
  })
}
/**
 * 运力 虚拟定位 添加规则
 * @returns {*}
 */
export function Addvirtuals (data) {
  return request({
    url: `capacity/virtuals`,
    method: 'post',
    data
  })
}
/**
 * 运力 虚拟定位 编辑规则
 * @returns {*}
 */
export function Editvirtuals (id,data) {
  return request({
    url: `capacity/virtuals/${id}`,
    method: 'put',
    data
  })
}

/**
 * 运力 虚拟定位 列表删除
 * @returns {*}
 */
export function Delvirtuals (id) {
  return request({
    url: `capacity/virtuals/${id}`,
    method: 'delete',
  })
}
/**
 * 运力 虚拟定位 编辑规则详情
 * @returns {*}
 */
export function Detalisvirtuals (id, params) {
  return request({
    url: `capacity/virtuals/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运力 虚拟定位 详情 获取司机
 * @returns {*}
 */
export function Detailsget_config (id, params) {
  return request({
    url: `capacity/virtual/get_config/${id}`,
    method: 'get',
    params
  })
}
/**
 * 运力 虚拟定位 更新司机坐标接口
 * @returns {*}
 */
export function update_driver (id,data) {
  return request({
    url: `capacity/virtual/update_coord/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运力 虚拟定位 更新司机信息接口
 * @returns {*}
 */
export function update_driver_info (id,data) {
  return request({
    url: `capacity/virtual/update_driver_info/${id}`,
    method: 'post',
    data
  })
}
/**
 * 运力 虚拟定位 删除虚拟司机接口
 * @returns {*}
 */
export function delete_driver (id,data) {
  return request({
    url: `capacity/virtual/delete_driver/${id}`,
    method: 'delete',
    data
  })
}


/**
 * 服务商详情  合同信息
 * @returns {*}
 */
export function getreconciliation (id,params) {
  return request({
    url: `capacity/facilitator/contract_information/${id}`,
    method: 'get',
    params
  })
}
/**
 *  服务商详情 合同信息 更新签约
 * @returns {*}
 */
export function save_contract_award (id,data) {
  return request({
    url: `capacity/facilitator/save_contract_award/${id}`,
    method: 'post',
    data
  })
}
/**
 *  服务商详情 合同信息 电子签约
 * @returns {*}
 */
export function save_electron_contract_award (data) {
  return request({
    url: `capacity/facilitator/save_electron_contract_award`,
    method: 'post',
    data
  })
}
/**
 *  服务商详情 合同信息 下载电子合同附件
 * @returns {*}
 */
export function get_electron_contract_award_url (id,data) {
  return request({
    url: `capacity/facilitator/get_electron_contract_award_url/${id}`,
    method: 'post',
    data
  })
}
/**
 * 服务商详情 短信设置 获取
 * @returns {*}
 */
export function get_note_config (id,params) {
    return request({
        url: `capacity/facilitator/note_config/${id}`,
        method: 'get',
        params
    })
}

/**
 * 服务商详情 短信设置 保存
 * @returns {*}
 */
export function Saveeset_note_config (id,data) {
    return request({
        url: `capacity/facilitator/save_note_config/${id}`,
        method: 'post',
        data
    })
}







/**
 * 服务商详情 订单审核配置 列表
 * @returns {*}
 */
export function getorder_audits (id,params) {
  return request({
    url: `operation/order_audit/facilitator/${id}/list`,
    method: 'get',
    params
  })
}
/**
 * 服务商详情 订单审核配置 详情
 * @returns {*}
 */
export function Detailsorder_audits (id,id1,params) {
  return request({
    url: `operation/order_audit/facilitator/${id}/${id1}/info`,
    method: 'get',
    params
  })
}

//
/**
 * 服务商详情 订单审核配置 获取配置
 * @returns {*}
 */
export function getConfig (params) {
  return request({
    url: 'operation/order_audit/config',
    method: 'get',
    params
  })
}
/**
 * 服务商详情 订单审核配置 添加
 * @returns {*}
 */
export function Addorder_audits (id,data) {
  return request({
    url: `operation/order_audit/facilitator/${id}/save`,
    method: 'post',
    data
  })
}
/**
 * 服务商详情 订单审核配置 修改
 * @returns {*}
 */
export function Editorder_audits (id,id1,data) {
  return request({
    url: `operation/order_audit/facilitator/${id}/${id1}/put`,
    method: 'put',
    data
  })
}
/**
 * 服务商详情 订单审核配置 删除
 * @returns {*}
 */
export function Delorder_audits (id,id1) {
  return request({
    url: `operation/order_audit/facilitator/${id}/${id1}/delete`,
    method: 'delete',
  })
}




/**
 * 服务商详情 服务项目配置 列表
 * @returns {*}
 */
export function getservices_config (id,params) {
  return request({
    url: `operation/services_config_s/facilitator/${id}/list`,
    method: 'get',
    params
  })
}

/**
 * 服务商详情 服务项目配置 添加
 * @returns {*}
 */
export function Addservices_config (id,data) {
  return request({
    url: `operation/services_config_s/facilitator/${id}/save`,
    method: 'post',
    data
  })
}

/**
 * 服务商详情 服务项目配置 修改
 * @returns {*}
 */
export function Editservices_config (id,id1,data) {
  return request({
    url: `operation/services_config_s/facilitator/${id}/${id1}/put`,
    method: 'put',
    data
  })
}

/**
 * 服务商详情 服务项目配置 详情
 * @returns {*}
 */
export function Detailsservices_config (id,id1,params) {
  return request({
    url: `operation/services_config_s/facilitator/${id}/${id1}/info`,
    method: 'get',
    params
  })
}

/**
 * 服务商详情 服务项目配置 删除
 * @returns {*}
 */
export function Delservices_config (id,id1) {
  return request({
    url: `operation/services_config_s/facilitator/${id}/${id1}/delete`,
    method: 'delete',
  })
}
/**
 * 服务商详情 默认拍照模板 列表
 * @returns {*}
 */
export function getphoto_template_select (id,params) {
  return request({
    url: `operation/photo_template/select/${id}`,
    method: 'get',
    params
  })
}





/**
 * 服务商详情 示例图配置 列表
 * @returns {*}
 */
export function getsamples (id,params) {
  return request({
    url: `operation/samples_s/facilitator/${id}/list`,
    method: 'get',
    params
  })
}

/**
 * 服务商详情 示例图配置 添加
 * @returns {*}
 */
export function Addsamples (id,data) {
  return request({
    url: `operation/samples_s/facilitator/${id}/save`,
    method: 'post',
    data
  })
}

/**
 * 服务商详情 示例图配置 修改
 * @returns {*}
 */
export function Editsamples (id,id1,data) {
  return request({
    url: `operation/samples_s/facilitator/${id}/${id1}/put`,
    method: 'put',
    data
  })
}

/**
 * 服务商详情 示例图配置 删除
 * @returns {*}
 */
export function Delsamples (id,id1) {
  return request({
    url: `operation/samples_s/facilitator/${id}/${id1}/delete`,
    method: 'delete',
  })
}



/**
 * 服务商详情 拍照模板 列表
 * @returns {*}
 */
export function getphoto_templates (id,params) {
  return request({
    url: `operation/photo_template/facilitator/${id}/list`,
    method: 'get',
    params
  })
}
/**
 * 服务商详情 拍照模板 添加
 * @returns {*}
 */
export function Addphoto_templates (id,data) {
  return request({
    url: `operation/photo_template/facilitator/${id}/save`,
    method: 'post',
    data
  })
}
/**
 * 服务商详情 拍照模板 修改
 * @returns {*}
 */
export function Editphoto_templates (id,id1,data) {
  return request({
    url: `operation/photo_template/facilitator/${id}/${id1}/put`,
    method: 'put',
    data
  })
}
/**
 * 服务商详情 拍照模板 详情
 * @returns {*}
 */
export function Detalisphoto_templates (id,id1,params) {
  return request({
    url: `operation/photo_template/facilitator/${id}/${id1}/info`,
    method: 'get',
    params
  })
}
/**
 * 服务商详情 拍照模板 删除
 * @returns {*}
 */
export function Delphoto_templates (id,id1) {
  return request({
    url: `operation/samples_s/facilitator/${id}/${id1}/delete`,
    method: 'delete',
  })
}




/**
 * 服务商详情 订单分配 获取
 * @returns {*}
 */
export function getorder_apportion (id,params) {
  return request({
    url: `operation/order_apportion_s/facilitator/${id}/list`,
    method: 'get',
    params
  })
}


/**
 * 服务商详情 订单分配 新增
 * @returns {*}
 */
export function Addorder_apportion (id,data) {
  return request({
    url: `operation/order_apportion_s/facilitator/${id}/save`,
    method: 'post',
    data
  })
}
/**
 * 服务商详情 订单分配 修改
 * @returns {*}
 */
export function Editorder_apportion (id,data) {
  return request({
    url: `operation/photo_template/facilitator/${id}/${id1}/put`,
    method: 'put',
    data
  })
}
/**
 * 服务商详情 订单分配 获取详情
 * @returns {*}
 */
export function Detalisorder_apportion (id,id1,params) {
  return request({
    url: `operation/order_apportion_s/facilitator/${id}/${id1}/info`,
    method: 'get',
    params
  })
}




/**
 * 服务商详情 自动结算 列表
 * @returns {*}
 */
export function getauto_balances (id,params) {
  return request({
    url: `operation/auto_balances_s/facilitator/${id}/list`,
    method: 'get',
    params
  })
}

/**
 * 服务商详情 自动结算 添加
 * @returns {*}
 */
export function Addauto_balances (id,data) {
  return request({
    url: `operation/auto_balances_s/facilitator/${id}/save`,
    method: 'post',
    data
  })
}
/**
 * 服务商详情 自动结算 修改
 * @returns {*}
 */
export function Editauto_balances (id,id1,data) {
  return request({
    url: `operation/auto_balances_s/facilitator/${id}/${id1}/put`,
    method: 'put',
    data
  })
}
/**
 * 服务商详情 自动结算 删除
 * @returns {*}
 */
export function Delauto_balances (id,id1) {
  return request({
    url: `operation/auto_balances_s/facilitator/${id}/${id1}/delete`,
    method: 'delete',
  })
}



/**
 * 服务商详情 服务配置 获取配置
 * @returns {*}
 */
export function getservice_configuration (id,params) {
  return request({
    url: `capacity/facilitator/service_aging/${id}`,
    method: 'get',
    params
  })
}
/**
 * 服务商详情 服务配置 配置修改
 * @returns {*}
 */
export function Saveservice_aging (id,data) {
  return request({
    url: `capacity/facilitator/service_aging/${id}`,
    method: 'post',
    data
  })
}

/**
 * 服务商详情 服务配置 派单获取配置
 * @returns {*}
 */
export function getget_dispatch_config (id,params) {
  return request({
    url: `capacity/facilitator/dispatch_config/${id}`,
    method: 'get',
    params
  })
}
/**
 * 服务商详情 服务配置 新增OR修改派单配置接口
 * @returns {*}
 */
export function Savecreate_dispatch_config (id,data) {
  return request({
    url: `capacity/facilitator/dispatch_config/${id}`,
    method: 'post',
    data
  })
}
/**
 * 服务商详情 获取水印信息
 * @returns {*}
 */
export function get_watermark_info (id,params) {
  return request({
    url: `capacity/facilitator/watermark_info/${id}`,
    method: 'get',
    params
  })
}

/**
 * 服务商详情 获取水印配置
 * @returns {*}
 */
export function get_watermark (id,params) {
  return request({
    url: `capacity/facilitator/watermark_config/${id}`,
    method: 'get',
    params
  })
}

/**
 * 服务商详情 创建水印配置
 * @returns {*}
 */
export function Addsave_watermark (id,data) {
  return request({
    url: `capacity/facilitator/save_watermark/${id}`,
    method: 'post',
    data
  })
}
/**
 * 获取平台管理VIP信息接口
 * @returns {*}
 */
export function get_vip (params) {
  return request({
    url: 'platform/get_vip',
    method: 'get',
    params
  })
}


/**
 * 获取平台管理基本信息接口
 * @returns {*}
 */
export function facilitatorinfo (params) {
  return request({
    url: 'platform/facilitator/info',
    method: 'get',
    params
  })
}

/**
 * 服务商详情 ocr识别接口
 * @returns {*}
 */
export function ocr (data) {
  return request({
    url: `common/ocr/business_license`,
    method: 'post',
    data
  })
}


/**
 * 服务商详情 查询服务商与服务商合作接口
 * @returns {*}
 */
export function query_signatory (id,params) {
  return request({
    url: `capacity/other/query_signatory/${id}`,
    method: 'get',
    params
  })
}

/**
 * 服务商列表 开放合作状态
 * @returns {*}
 */
export function open_coop (id) {
  return request({
    url: `/facilitator/open_coop/${id}`,
    method: 'get',
  })
}




/**
 * 所有企业下拉列表 所有服务商
 * @returns {*}
 */
export function all_select (params) {
  return request({
    url: `facilitator/all_select`,
    method: 'get',
    params
  })
}
/**
 * 导出调度客服考核数据表
 * @returns {*}
 */
export function assess_data (params) {
  return request({
    url: `facilitator/assess_data`,
    method: 'get',
    params
  })
}

/**
 * 导出市场人员签约服务商数量考核数据
 * @returns {*}
 */
export function number_assess (params) {
  return request({
    url: `facilitator/number_assess`,
    method: 'get',
    params
  })
}

/**
 * 导出市场人员签约服务商质量考核数据
 * @returns {*}
 */
export function quantity_assess (params) {
  return request({
    url: `facilitator/quantity_assess`,
    method: 'get',
    params
  })
}
/**
 * 导出市场人员签约服务商质量考核数据
 * @returns {*}
 */
export function export_history (params) {
  return request({
    url: `facilitator/export_history`,
    method: 'get',
    params
  })
}
/**
 * 下载导出文件
 * @returns {*}
 */
export function export_download (params) {
  return request({
    url: `facilitator/download`,
    method: 'get',
    params
  })
}
