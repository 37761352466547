import axios from 'axios'
import Setting from '@/config/setting'
// import { Message } from 'view-design'
import { getCookies, removeCookies } from '@/utils/cookies'
import Config from '@/config'
import router from '@/router'
import { Notification, MessageBox, Message, Loading } from 'element-ui'

let downloadLoadingInstance;

const service = axios.create({
  baseURL: Setting.apiBaseURL,
  timeout: 10000 // 请求超时时间
})

axios.defaults.withCredentials = true// 携带cookie

/**
 * 请求拦截器
 */
service.interceptors.request.use(
  config => {
    config.baseURL = Setting.apiBaseURL
    if (config.file) {
      config.headers['Content-Type'] = 'multipart/form-data'
    }
    const token = getCookies(Config.tokenStoreName)
    if (token) {
      config.headers['X-Token'] = 'Bearer ' + token
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

/**
 * 响应拦截器
 */
service.interceptors.response.use(
  response => {
    const status = response.data ? response.data.code : 0
    const code = status
    const msg = response.data.msg
    // console.log(msg)
    // 判断接口返回请登录就跳转登录页面
    if(msg == '请登录'){
      Message.error(response.data.msg)
      localStorage.clear()
      removeCookies(Config.tokenStoreName)
      removeCookies('expires_time')
      removeCookies('uuid')
      router.replace({ path: '/admin/login' })
    }

    switch (code) {
      case 200:
        return response.data
      case 400011:case 400012:
        return Promise.reject(response.data || { msg: '未知错误' })
      case 410000:case 410001:case 410002:case 110003: case 110002:
        Message.error(response.data.msg)
        localStorage.clear()
        removeCookies(Config.tokenStoreName)
        removeCookies('expires_time')
        removeCookies('uuid')
        router.replace({ path: '/admin/login' })
        break
      case 410003:
      case 400:
        return response.data
      // removeCookies('kefuInfo')
      // removeCookies('kefu_token')
      // removeCookies('kefu_expires_time')
      // removeCookies('kefu_uuid')
      // router.replace({ path: '/kefu' })
      // eslint-disable-next-line no-fallthrough
      default:
        break
    }
  },
  error => {
    Message.error(error.msg)
    return Promise.reject(error)
  }
)

// 通用下载方法
export function download (url, params, filename, config) {
  downloadLoadingInstance = Loading.service({ text: "正在下载数据，请稍候", spinner: "el-icon-loading", background: "rgba(0, 0, 0, 0.7)", })
  return service.post(url, params, {
    transformRequest: [(params) => { return tansParams(params) }],
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    responseType: 'blob',
    ...config
  }).then(async (data) => {
    const isLogin = await blobValidate(data);
    if (isLogin) {
      const blob = new Blob([data])
      saveAs(blob, filename)
    } else {
      const resText = await data.text();
      const rspObj = JSON.parse(resText);
      const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default']
      Message.error(errMsg);
    }
    downloadLoadingInstance.close();
  }).catch((r) => {
    console.error(r)
    Message.error('下载文件出现错误，请联系管理员！')
    downloadLoadingInstance.close();
  })
}

export default service
