import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'

import VueLazyload from 'vue-lazyload'

// 图片查看器
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'

import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

// rem转换页面自适应
import '@/utils/detectZoom';

import AFTableColumn from 'af-table-column'
Vue.use(AFTableColumn)

// 自定义表单
import formCreate from '@form-create/element-ui'
import FcDesigner from '@form-create/designer'
// import ELEMENT from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// import 'swiper/css/swiper.css'

// swiper
// import VueAwesomeSwiper from 'vue-awesome-swiper'

// // 图片查看器
// import 'viewerjs/dist/viewer.css'
// import VueViewer from 'v-viewer'

// Vue.use(ELEMENT)
Vue.use(formCreate)
Vue.use(FcDesigner)

// 自定义样式
import '@/assets/my-theme/index.less'
// import '@/layout/theme/index.scss'
// import '@/layout/index.scss'
import '@/assets/scss/index.scss'
import '@/assets/iconfont/iconfont.css'

// 判断按需加载css 过滤表单设计以防样式污染
const isExcludedPage = window.location.pathname.includes('/FormParser');
console.log(isExcludedPage)
if(!isExcludedPage){
  require('@/layout/index.scss')
}

// 引入全局配置
import config from '@/config'
// ele-admin
import EleAdmin from 'ele-admin'

// 气泡提示框
import Utils from '@/utils/confirm'
Vue.prototype.$utils = window.$utils = Utils

//通用下载
import { download } from '@/utils/request'
Vue.prototype.download = download

// 自构组件
import Components from '@/components'
import Auths from '@/utils/auths'
import { authLapse } from '@/utils/authLapse'
import modalForm from '@/utils/modalForm'
import {
  modalSure,
  overallModal
} from '@/utils/public'

// 引入自定义表格组件
import MyTable from "@/components/MyTable"
Vue.component('MyTable', MyTable)
// 引入表格设置封装的组件
import PackageSortElement from "@/components/PackageSortElement";
Vue.component('PackageSortElement', PackageSortElement)

// 引入高德地图组件
import AMapLoader from "@/components/AMapLoader"
Vue.component('AMapLoader', AMapLoader)

// 引入拨打电话
import Phone from "@/components/phone";
Vue.component('Phone', Phone);

// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import echarts from "echarts"; // npm install echarts --save
Vue.prototype.$echarts = echarts;

//vueEventBus
import VueBus from 'vue-bus';
Vue.use(VueBus);

// 表单验证聚焦功能
import {firstInputFocus} from '@/utils/tool.js'
Vue.prototype.$firstInputFocus = firstInputFocus


Vue.use(EleAdmin)
Vue.use(ViewUI)
// Vue.use(VueAwesomeSwiper)
Vue.use(VXETable)
Vue.use(Components)
Vue.use(Auths)
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/images/no.png'),
  loading: require('./assets/images/moren.jpg'),
  attempt: 1,
  listenEvents: ['scroll', 'wheel', 'mousewheel', 'resize', 'animationend', 'transitionend', 'touchmove']
})
Vue.use(VueViewer,{
  defaultOptions:{
    zIndex: 9999,  // 图片查看器默认z-index(控制显示层级为最高)
  }
})
Vue.use(formCreate)
Vue.use(Element)

Vue.config.productionTip = false

Vue.prototype.$config = config
Vue.prototype.$modalForm = modalForm
Vue.prototype.$modalSure = modalSure
Vue.prototype.$overallModal = overallModal
Vue.prototype.$authLapse = authLapse

// 监听本地存储数据变化
Vue.prototype.$addStorageEvent = function (type, key, data) {
  let newStorageEvent;
  if (type === 1) {
    // 创建一个StorageEvent事件
    newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        localStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItemEvent', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    }
    return storage.setItem(key, data);
  } else {
    // 创建一个StorageEvent事件
    newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItemEvent', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    }
    return storage.setItem(key, data);
  }
}


new Vue({
  router,
  store,
  render: h => h(App),
  watch: {
    // 监听路由 控制侧边栏显示 标记当前顶栏菜单（如需要）
    $route (to, from) {
      const onRoutes = to.meta.activeMenu ? to.meta.activeMenu : ''
      this.$store.commit('menus/setActivePath', onRoutes)
      if (to.name == 'crud_crud') {
        this.$store.state.menus.oneLvRoutes.map((e) => {
          if (e.path === to.path) {
            to.meta.title = e.title
          }
        })
      }
      if (
        [].includes(to.name)
      ) {
        let route = to.matched[1].path.split(':')[0]
        this.$store.state.menus.oneLvRoutes.map((e) => {
          if (route.indexOf(e.path) != -1) {
            to.meta.title = `${e.title} ${to.params.id ? 'ID:' + to.params.id : ''}`
          }
        })
      }
    }
  }
}).$mount('#app')
