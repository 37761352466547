import Cookie from 'js-cookie'

// ===========================
// TODO: Cookies国度
// ===========================
const ls = localStorage
const ss = sessionStorage

/**
 * 获取Cookie
 * @param key 键
 * @param type 存储类型
 * @returns {string|boolean|*}
 */
export const getCookies = (key, type) => {
  return type === 'ls' ? ls.getItem(key) || false : type === 'ss' ? ss.getItem(key) || false : Cookie.get(key) || false
}

/**
 * 设置Cookie
 * @param value 值
 * @param key 键
 * @param type 存储类型
 * @returns {void|boolean|*}
 */
export const setCookies = (value, key, type) => {
  return type === 'ls' ? ls.setItem(value, key) || false : type === 'ss' ? ss.setItem(value, key) || false : Cookie.set(value, key) || false
}

/**
 * 删除Cookie
 * @param key 键
 * @param type 存储类型
 * @returns {void|boolean|*}
 */
export const removeCookies = (key, type) => {
  return type === 'ls' ? ls.removeItem(key) || false : type === 'ss' ? ss.removeItem(key) || false : Cookie.remove(key) || false
}
