<!-- 页脚 -->
<template>
  <div class="ele-text-center" style="padding: 16px 0;">
    <div>
      <a
        target="_blank"
        class="ele-text-secondary"
        href="http://www.rxthink.cn">

      </a>
      <em/>
      <a
        target="_blank"
        class="ele-text-secondary"
        href="http://docs.evtp6.pro.rxthink.cn">

      </a>
      <em/>
      <a
        target="_blank"
        class="ele-text-secondary"
        href="http://www.rxthink.cn/goods/detail/7">

      </a>
    </div>
    <div class="ele-text-secondary" style="margin-top: 8px;">

    </div>
  </div>
</template>

<script>
export default {
  name: 'EleFooter'
}
</script>
