import { getSystemAdminInfoApi,systemadmin_info } from '@/api/account'
import {
  formatMenus
} from 'ele-admin'
import setting from '@/config/config'

// 字典
import {
  getDictdata_childreny
} from '@/api/system'

import {removeCookies} from "@/utils/cookies";
import Config from "@/config";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    userInfo: {},
    roles: [],
    menus: [],
    uniqueAuth: [],
    dict:[],
  },
  mutations: {
    SET: (state, obj) => {
      state[obj.key] = obj.value
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERSINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus
    },
    SET_UNIQUEAUTH: (state, uniqueAuth) => {
      state.uniqueAuth = uniqueAuth
    },
    SET_DICT: (state, dict) => {
      state.dict = dict
    }
  },
  actions: {
    /**
     * 获取菜单权限
     * @param commit
     * @returns {Promise<unknown>}
     */
    getAdminInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getSystemAdminInfoApi().then(res => {
          const result = res.data
          commit('SET_USERSINFO', result.platform_info)
          this.commit('menus/setSliderData', result.menus)
          // 先格式化菜单数据
          // 处理菜单数据格式
          const { menus, homePath } = formatMenus(result.menus, setting.parseMenuItem)
          commit('SET', { key: 'menus', value: menus })
          commit('SET', { key: 'uniqueAuth', value: result.unique_auth })
          resolve({ menus: menus, home: homePath })
        }).catch(err => {
          console.log(err)
          if(err.msg === '请登录'){
            localStorage.clear()
            removeCookies(Config.tokenStoreName)
            removeCookies('expires_time')
            removeCookies('uuid')
            router.replace({ path: '/admin/login' })
          }
          reject(err)
        })
      })
    },

    /**
     * 获取全部字典数据
     * @param commit
     * @returns {Promise<unknown>}
     */
    getDict ({ commit }) {
      return new Promise((resolve, reject) => {
        // 获取字典板块
        // getDictdata_childreny().then(res => {
        //   // console.log(res.data)
        //   // 存储字典
        //   commit('SET', { key: 'dict', value: res.data });
        // }).catch(err => {
        //   reject(err)
        // })
      })
    }

  },
  modules: {
  }
}
