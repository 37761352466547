<template>
  <div class="Modal">
    <div class="colLeft">
      <!--<Row :wrap="false">-->
        <!--<Col flex="210px">-->
          <div class="Nav">
            <!--<div class="input">-->
            <!--  <Input search enter-button placeholder="请输入分类名称" v-model="uploadName.name" style="width: 90%" @on-search="changePage"/>-->
            <!--</div>-->
            <div class="trees-coadd">
              <div class="scollhide">
                <div class="trees">
                  <el-tree
                    :data="treeData"
                    node-key="id"
                    default-expand-all
                    highlight-current
                    :expand-on-click-node="false"
                    @node-click="appendBtn"
                    :current-node-key="treeId">
                    <span class="custom-tree-node" slot-scope="{ data }">
                      <span class="file-name"><i class="icon el-icon-folder-remove"></i>{{ data.title }}</span>
                      <span>
                        <Dropdown @on-click="handleClickMenu(data, $event)">
                          <Icon class="add" type="ios-more" />
                          <DropdownMenu slot="list">
                            <DropdownItem name="1">新增分类</DropdownItem>
                            <DropdownItem v-if="data.id" name="2">编辑分类</DropdownItem>
                            <DropdownItem v-if="data.id" name="3"><span class="l-color-error">删除</span></DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                        <!--<el-dropdown @command="(command) => clickMenu(data, command)">-->
                        <!--  <Icon class="add" type="ios-more" />-->
                        <!--  <template slot="dropdown">-->
                        <!--    <el-dropdown-menu>-->
                        <!--      <el-dropdown-item command="1">新增分类</el-dropdown-item>-->
                        <!--      <el-dropdown-item v-if="data.id" command="2">编辑分类</el-dropdown-item>-->
                        <!--      <el-dropdown-item v-if="data.id" command="3">删除</el-dropdown-item>-->
                        <!--    </el-dropdown-menu>-->
                        <!--  </template>-->
                        <!--</el-dropdown>-->
                      </span>
                    </span>
                  </el-tree>
                  <!--<Tree :data="treeData" :render="renderContent" :load-data="loadData" class="treeBox" ref="tree"></Tree>-->
                </div>
              </div>
            </div>
          </div>
        <!--</Col>-->
        <!--<Col flex="auto">-->
          <div class="conter">
            <div>
              <Row :wrap="false">
                <Col flex="auto">
                  <Button
                    type="primary"
                    :disabled="checkPicList.length === 0"
                    @click="checkPics"
                    class="l-m-r10"
                    v-if="isShow !== 0">
                    使用选中图片
                  </Button>
                  <Button class="l-m-r10" type="primary" @click="handleUploadModal">上传图片</Button>
                  <Button type="error" class="l-m-r10" :disabled="checkPicList.length === 0" @click.stop="deletePic('图片')">
                    删除图片
                  </Button>
                  <el-cascader
                    v-model="pids"
                    placeholder="图片移动至"
                    style="width: 150px"
                    class="treeSel"
                    :options="treeData2"
                    :props="{ checkStrictly: true, emitPath: false, label: 'title', value: 'id' }"
                    clearable
                    size="small"
                    @visible-change="handleMoveImg"></el-cascader>
                  <!--<i-select :value="pids" placeholder="图片移动至" style="width: 250px" class="treeSel">-->
                  <!--  <i-option v-for="(item, index) of list" :value="item.value" :key="index" style="display: none">-->
                  <!--    {{ item.title }}-->
                  <!--  </i-option>-->
                  <!--  <Tree-->
                  <!--    :data="treeData2"-->
                  <!--    :render="renderContentSel"-->
                  <!--    ref="reference"-->
                  <!--    :load-data="loadData"-->
                  <!--    class="treeBox"-->
                  <!--  ></Tree>-->
                  <!--</i-select>-->
                </Col>
                <Col flex="none">
                  <div v-if="isPage">
                    <el-input
                      class="l-m-r10"
                      v-model="fileData.real_name"
                      placeholder="请输入图片名"
                      size="small"
                      style="width: 150px">
                      <i slot="suffix" class="el-icon-search el-input__icon" @click="getFileListRequest"></i>
                    </el-input>
                    <el-radio-group v-model="lietStyle" size="small" @change="handleRadioChange">
                      <el-radio-button label="list">
                        <i class="el-icon-menu"></i>
                      </el-radio-button>
                      <el-radio-button label="table">
                        <i class="el-icon-files"></i>
                      </el-radio-button>
                    </el-radio-group>
                  </div>
                </Col>
              </Row>
            </div>
            <div class="pictrueList l-m-t10" :class="{ 'is-modal': !isPage }">
              <div v-if="lietStyle == 'list'" style="width: 100%">
                <div v-show="isShowPic" class="imagesNo">
                  <Icon type="ios-images" size="60" color="#dbdbdb" />
                  <span class="imagesNo_sp">图片库为空</span>
                </div>
                <div class="acea-row l-m-b10">
                  <div
                    class="pictrueList_pic l-m-r10 l-m-b10"
                    v-for="(item, index) in pictrueList"
                    :key="index"
                    @mouseenter="enterMouse(item)"
                    @mouseleave="enterMouse(item)">
                    <p class="number" v-if="item.num > 0">
                      <Badge :count="item.num" type="error" :offset="[11, 12]">
                        <a href="#" class="demo-badge"></a>
                      </Badge>
                    </p>
                    <img
                      :class="item.isSelect ? 'on' : ''"
                      v-lazy="item.satt_dir"
                      @click.stop="changImage(item, index, pictrueList)"/>
                    <div @mouseenter="enterLeave(item)" @mouseleave="enterLeave(item)">
                      <p v-if="!item.isEdit">
                        {{ item.editName }}
                      </p>
                      <el-input size="small" type="text" v-model="item.real_name" v-else @blur="bindTxt(item)" />
                      <div class="operate-item operate-height">
                        <span class="operate l-m-r10" @click="item.isEdit = !item.isEdit" v-if="item.isShowEdit">改名</span>
                        <span class="operate l-m-r10" @click="lookImg(item)" v-if="item.isShowEdit">查看</span>
                        <span class="operate l-color-error" @click="deletePic(item.att_id)" v-if="item.isShowEdit">删除</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <el-table
                v-if="lietStyle == 'table'"
                ref="table"
                :data="pictrueList"
                v-loading="loading"
                highlight-row
                :row-key="getRowKey"
                @selection-change="handleSelectRow"
                no-data-text="暂无数据"
                no-filtered-data-text="暂无筛选结果">
                <el-table-column type="selection" width="60" :reserve-selection="true"></el-table-column>
                <el-table-column label="图片名称" min-width="190">
                  <template slot-scope="scope">
                    <div class="df-aic">
                      <div class="tabBox_img l-m-r10" v-viewer>
                        <img v-lazy="scope.row.att_dir" />
                      </div>
                      <span v-if="!scope.row.isEdit" class="line2 real-name">{{ scope.row.real_name }}</span>
                      <el-input
                        size="small"
                        type="text"
                        style="width: 90%"
                        v-model="scope.row.real_name"
                        v-else
                        @blur="bindTxt(scope.row)"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="上传时间" min-width="100">
                  <template slot-scope="scope">
                    <span>{{ scope.row.time }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="170">
                  <template slot-scope="scope">
                    <a @click="lookImg(scope.row)">查看</a>
                    <el-divider direction="vertical"></el-divider>
                    <a @click="scope.row.isEdit = !scope.row.isEdit">{{ scope.row.isEdit ? '确定' : '重名命' }}</a>
                    <el-divider direction="vertical"></el-divider>
                    <a class="l-color-error" @click="deletePic(scope.row.att_id)">删除</a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="footer acea-row row-right l-m-t10">
              <Page :total="total" show-elevator show-total @on-change="pageChange" :page-size="fileData.limit" />
            </div>
          </div>
        <!--</Col>-->
      <!--</Row>-->
    </div>
    <UploadImg
      ref="upload"
      :isIframe="isIframe"
      :categoryId="treeId"
      :categoryList="treeData"
      @uploadSuccess="handleSuccess"></UploadImg>
    <div class="images" v-show="false" v-viewer="{ movable: false }">
      <img v-for="src in pictrueList" :src="src.att_dir" :key="src.att_id" />
    </div>
  </div>
</template>

<script>
import Setting from '@/config/setting'
import {
  getFileCategoryListApi,
  saveFileCreateCategoryApi,
  editFileCategoryApi,
  getFileListApi,
  moveFileOrCategoryApi,
  updateFileApi
} from '@/api/common'
import {
  getCookies
} from '@/utils/cookies'
import config from '@/config'

export default {
  name: 'UploadPictures',
  props: {
    isChoice: {
      type: String,
      default: ''
    },
    gridBtn: {
      type: Object,
      default: null
    },
    gridPic: {
      type: Object,
      default: null
    },
    isShow: {
      type: Number,
      default: 1
    },
    isPage: {
      type: Boolean,
      default: false
    },
    pageLimit: {
      type: Number,
      default: 0
    },
    isIframe: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      spinShow: false,
      fileUrl: Setting.apiBaseURL + '/file/upload',
      modalPic: false,
      treeData: [],
      treeData2: [],
      pictrueList: [],
      uploadData: {}, // 上传参数
      checkPicList: [],
      uploadName: {
        name: '',
        all: 1
      },
      FromData: null,
      treeId: 0,
      isJudge: false,
      buttonProps: {
        type: 'default',
        size: 'small'
      },
      fileData: {
        pid: 0,
        page: 1,
        limit: this.pageLimit || 18
      },
      total: 0,
      pids: 0,
      list: [],
      modalTitleSs: '',
      isShowPic: false,
      header: {},
      ids: [], // 选中附件的id集合
      multipleSelection: [],

      lietStyle: 'list',
      loading: false
    }
  },
  mounted () {
    this.getToken()
    this.getFileCategoryList()
    this.getFileListRequest()
  },
  methods: {
    // 获取素材分类列表
    getFileCategoryList (type) {
      let data = {
        title: '全部图片',
        id: '',
        pid: 0,
        selected: true
      }
      getFileCategoryListApi(this.uploadName).then(async (res) => {
        this.treeData = res.data.list
        this.treeData.unshift(data)
        if (type !== 'search') {
          this.treeData2 = [...this.treeData]
        }
        this.addFlag(this.treeData)
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 获取文件素材列表
    getFileListRequest () {
      this.fileData.pid = this.treeId
      getFileListApi(this.fileData).then(async (res) => {
        res.data.list.forEach((el) => {
          el.isSelect = false
          el.isEdit = false
          el.isShowEdit = false
          el.realName = false
          el.num = 0
          this.editName(el)
        })
        this.pictrueList = res.data.list
        if (this.pictrueList.length) {
          this.isShowPic = false
        } else {
          this.isShowPic = true
        }
        this.total = res.data.count
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 上传头部token
    getToken () {
      this.header['Authori-zation'] = 'Bearer ' + getCookies(config.tokenStoreName)
    },
    enterMouse (item) {
      item.realName = !item.realName
    },
    enterLeave (item) {
      item.isShowEdit = !item.isShowEdit
    },
    // 分类树
    renderContent (h, { root, node, data }) {
      let operate = []
      if (data.pid == 0) {
        operate.push(
          h('DropdownItem', {
            on: {
              click: () => {
                this.handleAppend(root, node, data)
              }
            }
          }, '添加分类')
        )
      }
      if (data.id !== '') {
        operate.push(
          h('DropdownItem', {
            class: ['ivu-dropdown-item'],
            on: {
              click: () => {
                this.editPic(root, node, data)
              }
            }
          }, '编辑分类'),
          h('DropdownItem', {
            class: ['ivu-dropdown-item l-color-error'],
            on: {
              click: () => {
                this.remove(root, node, data, '分类')
              }
            }
          }, '删除分类')
        )
      }
      return h('div', {
        class: ['custom-tree-node'],
        style: {},
        on: {
          mouseenter: () => {
            this.onMouseOver(root, node, data)
          },
          mouseleave: () => {
            this.onMouseOver(root, node, data)
          }
        }
      }, [
        // 文件名称
        h('span', {
          class: ['file-name'],
          on: {
            click: (e) => {
              this.appendBtn(root, node, data, e)
            }
          }
        }, [
          h('Icon', {
            class: ['icon'],
            props: {
              type: 'ios-folder-outline'
            },
            style: {}
          }),
          data.title
        ]),
        h('div', {
          style: {
            display: 'inline-block',
            float: 'right'
          }
        }, [
          h('Dropdown', {
            props: {
              placement: 'bottom-end'
            }
          }, [
            h('Icon', {
              class: ['icon'],
              props: {
                type: 'ios-more'
              },
              style: {
                marginRight: '8px',
                fontSize: '20px',
                display: 'inline'
              }
            }),
            h('DropdownMenu', {
              slot: 'list'
            }, operate)
          ])
        ])
      ])
    },
    // 点击添加
    handleAppend (root, node, data) {
      this.treeId = data.id
      this.getFileCategoryFrom()
    },
    // 移动分类树
    renderContentSel (h, { root, node, data }) {
      return h('div', {
        style: {
          display: 'inline-block',
          width: '90%'
        }
      }, [
        h('span', [
          h('span', {
            style: {
              cursor: 'pointer'
            },
            class: ['ivu-tree-title'],
            on: {
              click: (e) => {
                this.handleCheckChange(root, node, data, e)
              }
            }
          },
          data.title)
        ])
      ])
    },
    // 下拉树
    handleCheckChange (root, node, data, e) {
      this.list = []
      let value = data.id
      let title = data.title
      this.list.push({ value, title })
      if (this.ids.length) {
        this.pids = value
        this.handleMove()
      } else {
        this.$Message.warning('请先选择图片')
      }
      let selected = this.$refs.reference.$el.querySelectorAll('.ivu-tree-title-selected')
      for (let i = 0; i < selected.length; i++) {
        selected[i].className = 'ivu-tree-title'
      }
      e.path[0].className = 'ivu-tree-title  ivu-tree-title-selected' // 当前点击的元素
    },
    // 移动分类
    handleMoveImg (status) {
      if (!status) {
        this.handleMove()
      } else {
        if (!this.ids.toString()) {
          this.$Message.warning('请先选择图片')
        }
      }
    },
    handleMove () {
      let data = {
        pid: this.pids,
        images: this.ids.toString()
      }
      if (!data.images) return
      moveFileOrCategoryApi(data).then(async (res) => {
        this.$Message.success(res.msg)
        this.getFileListRequest()
        this.pids = 0
        this.checkPicList = []
        this.ids = []
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    handleRadioChange () {
      this.initData()
    },
    initData () {
      this.checkPicList = []
      this.ids = []
      this.multipleSelection = []
    },
    // 删除图片
    deletePic (id) {
      let ids = {
        ids: id || this.ids.toString()
      }
      let delfromData = {
        title: '删除选中图片',
        url: 'file/file/delete',
        method: 'POST',
        ids: ids
      }
      this.$overallModal(delfromData).then((res) => {
        this.$Message.success(res.msg)
        this.getFileListRequest()
        this.checkPicList = []
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 鼠标移入 移出
    onMouseOver (root, node, data) {
      event.preventDefault()
      data.flag = !data.flag
      if (data.flag2) {
        data.flag2 = false
      }
    },
    onClick (root, node, data, e) {
      e.preventDefault()
      data.flag2 = !data.flag2
    },
    // 点击树
    appendBtn (data) {
      this.treeId = data.id
      this.fileData.page = 1
      this.getFileListRequest()
    },
    // 删除分类
    remove (root, node, data, tit) {
      this.tits = tit
      let delfromData = {
        title: '删除 [ ' + root.title + ' ] ' + '分类',
        url: `file/category/${root.id}`,
        method: 'DELETE',
        ids: ''
      }
      this.$overallModal(delfromData).then((res) => {
        this.$Message.success(res.msg)
        this.getFileCategoryList()
        this.checkPicList = []
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 编辑树表单
    editPic (root, node, data) {
      this.$modalForm(editFileCategoryApi(data.id)).then(() => this.getFileCategoryList())
    },
    // 搜索分类
    changePage () {
      this.getFileCategoryList('search')
    },
    loadData (item, callback) {
      getFileCategoryListApi({ pid: item.id }).then(async (res) => {
        const data = res.data.list
        callback(data)
      }).catch((err) => {
        console.log(err.msg)
      })
    },
    addFlag (treedata) {
      treedata.map((item) => {
        this.$set(item, 'flag', false)
        this.$set(item, 'flag2', false)
        item.children && this.addFlag(item.children)
      })
    },
    // 新建分类
    add () {
      this.treeId = 0
      this.getFileCategoryFrom()
    },
    // 新建分类表单
    getFileCategoryFrom () {
      this.$modalForm(saveFileCreateCategoryApi({ id: this.treeId })).then((res) => {
        this.getFileCategoryList()
      })
    },
    // 上传模态框
    handleUploadModal () {
      this.$refs.upload.modal = true
    },
    // 上传之前
    beforeUpload (file) {
      // if (file.size > 2097152) {
      //   this.$Message.error(file.name + "大小超过2M!");
      // } else
      if (!/image\/\w+/.test(file.type)) {
        this.$Message.error('请上传以jpg、jpeg、png等结尾的图片文件') // FileExt.toLowerCase()
        return false
      }
      this.uploadData = {
        pid: this.treeId
      }
      let promise = new Promise((resolve) => {
        this.$nextTick(function () {
          resolve(true)
        })
      })
      return promise
    },
    // 上传成功
    handleSuccess () {
      this.fileData.page = 1
      this.getFileListRequest()
    },
    // 关闭
    cancel () {
      this.$emit('changeCancel')
    },
    // 选中图片
    changImage (item, index, row) {
      let activeIndex = 0
      if (!item.isSelect) {
        item.isSelect = true
        this.checkPicList.push(item)
      } else {
        item.isSelect = false
        this.checkPicList.map((el, index) => {
          if (el.att_id == item.att_id) {
            activeIndex = index
          }
        })
        this.checkPicList.splice(activeIndex, 1)
      }

      this.ids = []
      this.checkPicList.map((item, i) => {
        this.ids.push(item.att_id)
      })
      this.pictrueList.map((el, i) => {
        if (el.isSelect) {
          this.checkPicList.filter((el2, j) => {
            if (el.att_id == el2.att_id) {
              el.num = j + 1
            }
          })
        } else {
          el.num = 0
        }
      })
    },
    // 点击使用选中图片
    checkPics () {
      if (this.isChoice === '单选') {
        if (this.checkPicList.length > 1) return this.$Message.warning('最多只能选一张图片')
        this.$emit('getPic', this.checkPicList[0])
      } else {
        let maxLength = this.$route.query.maxLength
        if (maxLength != undefined && this.checkPicList.length > Number(maxLength)) { return this.$Message.warning('最多只能选' + maxLength + '张图片') }
        this.$emit('getPicD', this.checkPicList)
      }
    },
    editName (item) {
      let it = item.real_name.split('.')
      let it1 = it[1] == undefined ? [] : it[1]
      let len = it[0].length + it1.length
      item.editName = len < 10 ? item.real_name : item.real_name.substr(0, 2) + '...' + item.real_name.substr(-5, 5)
    },
    // 修改图片文字上传
    bindTxt (item) {
      if (item.real_name == '') {
        this.$Message.error('请填写内容')
      }
      updateFileApi(item.att_id, {
        real_name: item.real_name
      })
        .then((res) => {
          this.editName(item)
          item.isEdit = false
          this.$Message.success(res.msg)
        })
        .catch((error) => {
          this.$Message.error(error.msg)
        })
    },
    // 分页加载数据
    pageChange (index) {
      this.fileData.page = index
      this.getFileListRequest()
      this.checkPicList = []
    },
    getRowKey (row) {
      return row.att_id
    },
    // 选中某一行
    handleSelectRow (selection) {
      let arr = this.unique(selection)
      const uniqueArr = []
      const ids = []
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i]
        if (!ids.includes(item.att_id)) {
          uniqueArr.push(item)
          ids.push(item.att_id)
        }
      }
      this.ids = ids
      this.multipleSelection = uniqueArr
    },
    // 对象数组去重
    unique (arr) {
      let result = arr.reduce((acc, curr) => {
        const x = acc.find((item) => item.att_id === curr.att_id)
        if (!x) {
          return acc.concat([curr])
        } else {
          return acc
        }
      }, [])
      return result
    },
    lookImg (item) {
      this.imageUrl = item.att_dir
      const viewer = this.$el.querySelector('.images').$viewer
      viewer.show()
      this.$nextTick(() => {
        let i = this.pictrueList.findIndex((e) => e.att_dir === item.att_dir)
        viewer.update().view(i)
      })
    },
    handleClickMenu (data, name) {
      if (name == 1) {
        this.appendBtn(data)
      } else if (name == 2) {
        this.editPic(data)
      } else if (name == 3) {
        this.remove(data, '分类')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.Modal {
  width: 100%;
  height: 100%;
  background: #fff !important;
}

.Nav {
  //width: 210px;
  min-width: 210px;
  max-width: 210px;
  border-right: 1px solid #eee;
}

.colLeft {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  padding-right: 0 !important;
}

.treeBox {
  margin-right: 10px;
  ::v-deep .ivu-tree-children {
    li:hover {
      background-color: #f5f7fa;
    }
    li:has(> .ivu-tree-title-selected) {
      background-color: #d5e8fc;
      .custom-tree-node {
        color: #1890ff!important;
      }
    }
    li {
      display: flex;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 32px;
      //line-height: 32px;
      margin: 0 !important;
      cursor: pointer;

      .ivu-tree-title {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
      }
      .ivu-tree-title:hover {
        background-color: transparent;
      }
      .ivu-tree-title-selected, .ivu-tree-title-selected:hover {
        background-color: transparent;
      }
      .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        //padding-right: 17px;
        font-size: 13px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        line-height: 13px;
      }
      .file-name {
        display: flex;
        align-items: center;
        .icon {
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }
      }
    }
  }
}

.conter {
  width: 100%;
  height: 100%;
  margin-left: 10px !important;
  .pictrueList {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .pictrueList img {
    width: 100%;
  }
  .pictrueList img.on {
    border: 2px solid #5fb878;
  }
  .is-modal .pictrueList_pic {
    width: 100px;
    margin-right: 10px !important;
    img {
      width: 100%;
      height: 100px;
    }
  }
  .pictrueList_pic {
    position: relative;
    width: 146px;
    cursor: pointer;
    margin-right: 19px !important;
    img {
      width: 146px;
      height: 146px;
      object-fit: cover;
    }
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 20px;
      font-size: 12px;
      color: #515a6d;
      text-align: center;
    }
    .number {
      height: 33px;
    }
    .number {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .tabBox_img {
    display: flex;
    align-items: center;
  }
  .real-name {
    flex: 1;
  }
  .df-aic {
    display: flex;
    align-items: center;
  }
}

.operate-item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.operate-height {
  height: 16px;
}
.operate {
  color: #1890ff;
  font-size: 12px;
  white-space: nowrap;
}
.imagesNo {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 65px 0;

  .imagesNo_sp {
    font-size: 13px;
    color: #dbdbdb;
    line-height: 3;
  }
}
/deep/ .file-name {
  display: flex;
  align-items: center;
  .icon {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
}
/deep/ .el-tree-node {
  margin-right: 16px;
}
/deep/ .el-tree-node__children .el-tree-node {
  margin-right: 0;
}
/deep/ .el-tree-node__content {
  width: 100%;
  height: 36px;
}
/deep/ .custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 17px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.6);
  line-height: 13px;
}
/deep/ .is-current {
  background: #f1f9ff !important;
  color: #1890ff !important;
}
/deep/ .is-current .custom-tree-node {
  color: #1890ff !important;
}
</style>
