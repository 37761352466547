import useI18n from '@/config'
import { cloneDeep } from 'lodash'
import store from "@/store";
import {includeArray} from "@/libs/auth";

/**
 * 米转换为千米
 */
export function kmUnit(m){
  var v;
  if(typeof m === 'number' && !isNaN(m)){
    if (m >= 1000) {
      v = (m / 1000).toFixed(2) + 'km'
    } else {
      v = m + 'm'
    }
  }else{
    v = '0m'
  }
  return v;
}

export function transListToTreeData(list, RootValue) {
  let arr = [];
  list.forEach((item) => {
    //如果数据对象的pid为 '' 说明数据为根节点
    if (item.pid === RootValue) {
      let children = transListToTreeData(list, item.id); //寻找id等于该对象pid的数据，为它的子节点,返回一个数组
      if (children.length) {
        item.children = children; //往对象添加一个children属性，属性值为一个数组
      }
      arr.push(item);
    }
  });
  return arr;
}


/**
 * 树形数据
 */
export function formatTreeDept (data, idKey= 0) {
  console.log(data);
  let result = []
  data.forEach((items) => {
    // console.log(items.pid, idKey)
    if (items.pid == idKey) {
      // console.log(items)

      // items.radio = '';
      const children = formatTreeDept(data, items.id)
      console.log(children)
      children.forEach(res => {
        // delete res.ratio
      })
      items.children = children
      // delete items.radio
      result.push(items)
    }
  })
  console.log(result)
  return result
}

/**
 * 设置标题
 * @param item
 * @param vm
 * @returns {*}
 */
export const showTitle = (item, vm) => {
  let { title, __titleIsFunction__ } = item.meta
  if (!title) return
  if (useI18n) {
    if (title.includes('{{') && title.includes('}}') && useI18n) {
      title = title.replace(/({{[\s\S]+?}})/, (m, str) => str.replace(/{{([\s\S]*)}}/, (m, _) => vm.$t(_.trim())))
    } else {
      if (__titleIsFunction__) title = item.meta.title
      else title = vm.$t(item.name)
    }
  } else title = (item.meta && item.meta.title) || item.name
  return title
}

export const findNodeUpperByClasses = (ele, classes) => {
  let parentNode = ele.parentNode
  if (parentNode) {
    let classList = parentNode.classList
    if (classList && classes.every((className) => classList.contains(className))) {
      return parentNode
    } else {
      return findNodeUpperByClasses(parentNode, classes)
    }
  }
}

export const R = (menuList, newOpenMenus) => {
  menuList.forEach((item) => {
    let newMenu = {}
    for (let i in item) {
      if (i !== 'children') newMenu[i] = cloneDeep(item[i])
    }
    newOpenMenus.push(newMenu)
    item.children && R(item.children, newOpenMenus)
  })
  return newOpenMenus
}

export function getMenuopen (to, menuList) {
  const allMenus = []
  menuList.forEach((menu) => {
    const menus = transMenu(menu, [])
    allMenus.push({
      path: menu.path,
      openNames: []
    })
    menus.forEach((item) => allMenus.push(item))
  })
  const currentMenu = allMenus.find((item) => item.path === to.path)
  return currentMenu ? currentMenu.openNames : []
}

function transMenu (menu, openNames) {
  if (menu.children && menu.children.length) {
    const itemOpenNames = openNames.concat([menu.path])
    return menu.children.reduce((all, item) => {
      all.push({
        path: item.path,
        openNames: itemOpenNames
      })
      const foundChildren = transMenu(item, itemOpenNames)
      return all.concat(foundChildren)
    }, [])
  } else {
    return [menu].map((item) => {
      return {
        path: item.path,
        openNames: openNames
      }
    })
  }
}

/**
 * @param {Array} routers 路由列表数组
 * @description 用于找到路由列表中name为home的对象
 */
export const getHomeRoute = (routers, homeName = 'home') => {
  let i = -1
  let len = routers.length
  let homeRoute = {}
  while (++i < len) {
    let item = routers[i]
    if (item.children && item.children.length) {
      let res = getHomeRoute(item.children, homeName)
      if (res.name) return res
    } else {
      if (item.name === homeName) homeRoute = item
    }
  }
  return homeRoute
}

/**
 * 本地存储和获取标签导航列表
 * @param list
 */
export const setTagNavListInLocalstorage = (list) => {
  localStorage.tagNaveList = JSON.stringify(list)
}

/**
 * @param {*} list 现有标签导航列表
 * @param {*} newRoute 新添加的路由原信息对象
 * @description 如果该newRoute已经存在则不再添加
 */
export const getNewTagList = (list, newRoute) => {
  const { name, path, meta } = newRoute
  let newList = [...list]
  if (newList.findIndex((item) => item.name === name) >= 0) return newList
  else newList.push({ name, path, meta })
  return newList
}

/**
 * @returns {Array} 其中的每个元素只包含路由原信息中的name, path, meta三项
 */
export const getTagNavListFromLocalstorage = () => {
  const list = localStorage.tagNaveList
  return list ? JSON.parse(list) : []
}

/**
 * @param {Array} list 标签列表
 * @param {String} name 当前关闭的标签的name
 */
export const getNextRoute = (list, route) => {
  let res = {}
  if (list.length === 2) {
    res = getHomeRoute(list)
  } else {
    const index = list.findIndex((item) => routeEqual(item, route))
    if (index === list.length - 1) res = list[list.length - 2]
    else res = list[index + 1]
  }
  return res
}

/**
 * 当前路由metched
 * @param route
 * @param homeRoute
 * @returns {[{icon: *, to},...{meta: ({title}|*), icon: (*|string), name}[]]|[(*&{icon: *})]}
 */
export const getBreadCrumbList = (route, homeRoute) => {
  let homeItem = { ...homeRoute, icon: homeRoute.meta?.icon }
  let routeMetched = route.matched
  if (routeMetched.some((item) => item.name === homeRoute.name)) return [homeItem]
  let res = routeMetched
    .filter((item) => {
      return item.meta === undefined || !item.meta.hideInBread
    })
    .map((item) => {
      let meta = { ...item.meta }
      if (meta.title && typeof meta.title === 'function') {
        meta.__titleIsFunction__ = true
        meta.title = meta.title(route)
      }
      let obj = {
        icon: (item.meta && item.meta.icon) || '',
        name: item.name,
        meta: meta
      }
      return obj
    })
  res = res.filter((item) => {
    return !item.meta.hideInMenu
  })
  return [{ ...homeItem, to: homeRoute.path }, ...res]
}

/**
 * 回调函数需要执行的次数
 * @param times
 * @param callback
 */
export const doCustomTimes = (times, callback) => {
  let i = -1
  while (++i < times) {
    callback(i)
  }
}

/**
 * 判断打开的标签列表里是否已存在这个新添加的路由对象
 * @param tagNavList
 * @param routeItem
 * @returns {boolean}
 */
export const routeHasExist = (tagNavList, routeItem) => {
  let len = tagNavList.length
  let res = false
  doCustomTimes(len, (index) => {
    if (routeEqual(tagNavList[index], routeItem)) res = true
  })
  return res
}

/**
 * 根据name/params/query判断两个路由对象是否相等
 * @param route1
 * @param route2
 * @returns {false|*}
 */
export const routeEqual = (route1, route2) => {
  const params1 = route1.params || {}
  const params2 = route2.params || {}
  const query1 = route1.query || {}
  const query2 = route2.query || {}
  return route1.name === route2.name && objEqual(params1, params2) && objEqual(query1, query2)
}

/**
 * @param {*} obj1 对象
 * @param {*} obj2 对象
 * @description 判断两个对象是否相等，这两个对象的值只能是数字或字符串
 */
export const objEqual = (obj1, obj2) => {
  const keysArr1 = Object.keys(obj1)
  const keysArr2 = Object.keys(obj2)
  if (keysArr1.length !== keysArr2.length) {
    return false
  } else {
    if (keysArr1.length === 0 && keysArr2.length === 0) {
      return true
    } else {
      // eslint-disable-next-line eqeqeq
      /* eslint-disable-next-line */
      return !keysArr1.some((key) => obj1[key] != obj2[key])
    }
  }
}

export const getRouteTitleHandled = (route) => {
  let router = { ...route }
  let meta = { ...route.meta }
  let title = ''
  if (meta.title) {
    if (typeof meta.title === 'function') {
      meta.__titleIsFunction__ = true
      title = meta.title(router)
    } else title = meta.title
  }
  meta.title = title
  router.meta = meta
  return router
}

// scrollTop animation
export const scrollTop = (el, from = 0, to, duration = 500, endCallback) => {
  if (!window.requestAnimationFrame) {
    window.requestAnimationFrame =
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      window.msRequestAnimationFrame ||
      function (callback) {
        return window.setTimeout(callback, 1000 / 60)
      }
  }
  const difference = Math.abs(from - to)
  const step = Math.ceil((difference / duration) * 50)

  const scroll = (start, end, step) => {
    if (start === end) {
      endCallback && endCallback()
      return
    }

    let d = start + step > end ? end : start + step
    if (start > end) {
      d = start - step < end ? end : start - step
    }

    if (el === window) {
      window.scrollTo(d, d)
    } else {
      el.scrollTop = d
    }
    window.requestAnimationFrame(() => scroll(d, end, step))
  }
  scroll(from, to, step)
}

/**
 * 重构格式化 菜单权限管理列表树形结构
 * @returns {*[]}
 */
export function menusTreeFormat (data) {
  let arr = []
  data.forEach(element => {
    let item = {}
    item.id = element.id
    item.pid = element.pid
    item.icon = element.icon
    item.title = element.title
    item.selected = false
    item.expand = false
    item.contextmenu = true
    if (element.children && element.children.length !== 0) {
      item.children = menusTreeFormat(element.children)
    }
    item.menus_type = element.menus_type
    item.status = element.status
    item.sort = element.sort
    item.is_show = element.is_show
    return arr.push(item)
  })
  return arr
}

/**
 * 格式化时间
 * @param date
 * @param fmt
 * @returns {*}
 */
export function formatDate (date, fmt) {
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + ''
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? str : padLeftZero(str))
    }
  }
  return fmt
}

function padLeftZero (str) {
  return ('00' + str).substr(str.length)
}


/**
 * 判断权限是否包含存在
 * @param value 当前权限
 * @returns {boolean}
 * @constructor
 */
export function ShowTable (value) {
  const access = store.state.admin.uniqueAuth
  const isPermission = includeArray(value, access)
  return !isPermission ? false : true
}

export function isNumberStr(str) {
  return /^[+-]?(0|([1-9]\d*))(\.\d+)?$/g.test(str)
}

export const exportDefault = 'export default '
export const beautifierConf = {
  html: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'separate',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: false,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  },
  js: {
    indent_size: '2',
    indent_char: ' ',
    max_preserve_newlines: '-1',
    preserve_newlines: false,
    keep_array_indentation: false,
    break_chained_methods: false,
    indent_scripts: 'normal',
    brace_style: 'end-expand',
    space_before_conditional: true,
    unescape_strings: false,
    jslint_happy: true,
    end_with_newline: true,
    wrap_line_length: '110',
    indent_inner_html: true,
    comma_first: false,
    e4x: true,
    indent_empty_lines: true
  }
}

// 深拷贝对象
export function deepClone(obj) {
  const _toString = Object.prototype.toString

  // null, undefined, non-object, function
  if (!obj || typeof obj !== 'object') {
    return obj
  }

  // DOM Node
  if (obj.nodeType && 'cloneNode' in obj) {
    return obj.cloneNode(true)
  }

  // Date
  if (_toString.call(obj) === '[object Date]') {
    return new Date(obj.getTime())
  }

  // RegExp
  if (_toString.call(obj) === '[object RegExp]') {
    const flags = []
    if (obj.global) { flags.push('g') }
    if (obj.multiline) { flags.push('m') }
    if (obj.ignoreCase) { flags.push('i') }

    return new RegExp(obj.source, flags.join(''))
  }

  const result = Array.isArray(obj) ? [] : obj.constructor ? new obj.constructor() : {}

  for (const key in obj) {
    result[key] = deepClone(obj[key])
  }

  return result
}


export function isObjectObject(t) {
  return toStr(t) === '[object Object]'
}

// 首字母大小
export function titleCase(str) {
  return str.replace(/( |^)[a-z]/g, L => L.toUpperCase())
}




// 大屏相关代码
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function() {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function(...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

