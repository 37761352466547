import request from '@/utils/request'

/**
 * 表格公共删除方法
 * @param data
 * @returns {*}
 */
export function tableDelApi (data) {
  return request({
    url: data.url,
    method: data.method,
    data: data.ids
  })
}

/**
 * [数据配置]配置分类公共结构接口
 * @param data
 * @returns {*}
 */
export function customConfigSomeApi (data) {
  return request({
    url: data.url,
    method: data.method,
    data: data.params ?? {}
  })
}

/**
 * 获取版权信息
 * @returns {*}
 */
export function getCopyRight () {
  return request({
    url: 'common/get_copyright',
    method: 'get'
  })
}

export function jnoticeRequestApi () {}

export function getWorkermanUrlApi () {}

/**
 * 获取Logo
 * @returns {*}
 */
export function getLogoApi () {
  return request({
    url: '/common/logo',
    method: 'get'
  })
}

// =============================
// TODO: 素材列表管理国度
// =============================
/**
 * 附件分类--列表
 * @param params
 * @returns {*}
 */
export function getFileCategoryListApi (params) {
  return request({
    url: 'file/category',
    method: 'get',
    params
  })
}

/**
 * @description 添加分类
 */
export function saveFileCreateCategoryApi (id) {
  return request({
    url: 'file/category/create',
    method: 'get',
    params: id
  })
}

/**
 * 编辑分类
 * @param id
 * @returns {*}
 */
export function editFileCategoryApi (id) {
  return request({
    url: `file/category/${id}/edit`,
    method: 'get'
  })
}

/**
 * 删除分类
 * @param id
 * @returns {*}
 */
export function deleteFileCategoryApi (id) {
  return request({
    url: `file/category/${id}`,
    method: 'DELETE'
  })
}

/**
 * 附件列表
 * @param params
 * @returns {*}
 */
export function getFileListApi (params) {
  return request({
    url: 'file/file',
    method: 'get',
    params
  })
}

/**
 * 移动分类，修改附件分类表单
 * @param data
 * @returns {*}
 */
export function moveFileOrCategoryApi (data) {
  return request({
    url: 'file/file/do_move',
    method: 'put',
    data
  })
}

/**
 * 修改附件名称
 * @param ids
 * @param data
 * @returns {*}
 */
export function updateFileApi (ids, data) {
  return request({
    url: 'file/file/update/' + ids,
    method: 'put',
    data
  })
}

/**
 * 图片上传
 * @param data
 * @param token
 * @returns {*}
 */
export function fileUploadApi (data, token) {
  return request({
    url: 'file/upload',
    method: 'post',
    headers: {
      'Authori-zation': 'Bearer ' + token,
      'content-type': 'multipart/form-data;' + 'Bearer ' + token
    },
    data
  })
}

/**
 * 删除附件
 * @param ids
 * @returns {*}
 */
export function deleteFileApi (ids) {
  return request({
    url: 'file/file/delete',
    method: 'post',
    data: ids
  })
}

/**
 * 网络图片上传
 * @param data
 * @returns {*}
 */
export function onlineUploadApi (data) {
  return request({
    url: 'file/online_upload',
    method: 'post',
    data
  })
}

/**
 * 扫码上传图片获取
 * @returns {*}
 */
export function scanUploadGetApi (scan_token) {
  return request({
    url: `file/scan_upload/image/${scan_token}`,
    method: 'get'
  })
}

/**
 * 清除扫码上传 code
 * @returns {*}
 */
export function scanUploadCodeApi () {
  return request({
    url: 'file/scan_upload/qrcode ',
    method: 'delete'
  })
}

/**
 * 扫码上传链接获取
 * @param pid
 * @returns {*}
 */
export function scanUploadQrcodeApi (pid) {
  return request({
    url: `file/scan_upload/qrcode?pid=${pid}`,
    method: 'get'
  })
}

/**
 * 扫码图片上传
 * @param data
 * @returns {*}
 */
export function scanUploadApi (data) {
  return request({
    url: 'image/scan_upload',
    method: 'post',
    headers: {
      'content-type': 'multipart/form-data;'
    },
    data
  })
}

// =============================
// TODO: 素材列表管理国度
// =============================

/**
 * 获取腾讯地图Key
 */
export function getTencentMapKeyApi () {
  return request({
    url: 'setting/config/get_tencent_key',
    method: 'get'
  })
}
