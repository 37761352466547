<!-- 顶栏右侧区域按钮 -->
<template>
  <div class="ele-admin-header-tool"  style="margin-left: -1%">
    <!-- 全屏切换 -->
    <!--<div class="ele-admin-header-tool-item hidden-xs-only" @click="changeFullscreen">-->
    <!--  <i :class="fullscreen ? 'el-icon-_screen-restore' : 'el-icon-_screen-full'"></i>-->
    <!--</div>-->

    <!-- 用户信息 -->
    <div class="ele-admin-header-tool-item">
      <el-dropdown @command="onUserDropClick">
        <div class="ele-admin-header-avatar">
          <el-avatar :src="loginUser.avatar"/>
          <span class="hidden-xs-only">{{ loginUser.account }}</span>
          <i class="el-icon-arrow-down hidden-xs-only"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <!-- <el-dropdown-item command="profile" icon="el-icon-user">
            个人中心
          </el-dropdown-item>
          <el-dropdown-item command="wallet" icon="el-icon-s-order">
            企业钱包
          </el-dropdown-item>
          <el-dropdown-item command="memberInformation" icon="el-icon-s-cooperation">
            会员信息
          </el-dropdown-item>
          <el-dropdown-item command="accountSettings" icon="el-icon-setting">
            账号设置
          </el-dropdown-item>
          <el-dropdown-item command="enterpriseInformation" icon="el-icon-sell">
            企业信息
          </el-dropdown-item> -->
          <!--<el-dropdown-item command="password" icon="el-icon-key">-->
          <!--  个人中心-->
          <!--</el-dropdown-item>-->
          <!--<el-dropdown-item command="logout" icon="el-icon-switch-button" divided>-->
          <!--  退出登录-->
          <!--</el-dropdown-item>-->
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 语言切换 -->
    <!--<div class="ele-admin-header-tool-item">-->
    <!--  <el-dropdown placement="bottom" @command="changeLanguage">-->
    <!--    <i class="el-icon-_language"></i>-->
    <!--    <el-dropdown-menu slot="dropdown">-->
    <!--      <el-dropdown-item command="en">-->
    <!--        <span :class="{'ele-text-primary': language === 'en'}">English</span>-->
    <!--      </el-dropdown-item>-->
    <!--      <el-dropdown-item command="zh_CN">-->
    <!--        <span :class="{'ele-text-primary': language === 'zh_CN'}">简体中文</span>-->
    <!--      </el-dropdown-item>-->
    <!--      <el-dropdown-item command="zh_TW">-->
    <!--        <span :class="{'ele-text-primary': language === 'zh_TW'}">繁體中文</span>-->
    <!--      </el-dropdown-item>-->
    <!--    </el-dropdown-menu>-->
    <!--  </el-dropdown>-->
    <!--</div>-->

    <!--<div class="ele-admin-header-tool-item" @click="goHome()">-->
    <!--  <img src="@/assets/images/home/home.png" alt="" class='imgwidthheight'>-->
    <!--</div>-->

    <!--im-->
    <!--<div class="ele-admin-header-tool-item">-->
    <!--  <el-badge :value="12" class="item">-->
    <!--    <img src="@/assets/images/home/xiaoxi1.png" alt="" class='imgwidthheight'>-->
    <!--  </el-badge>-->
    <!--</div>-->

    <!-- 主题设置 -->
    <!--<div v-if="showSetting" class="ele-admin-header-tool-item" @click="openSetting">-->
    <!--  &lt;!&ndash;<i class="el-icon-_more"></i>&ndash;&gt;-->
    <!--  <img src="@/assets/images/home/pifu.png" alt="">-->
    <!--</div>-->

    <div class="ele-admin-header-tool-item" @click="logout">
      <img src="@/assets/images/home/tuichu.png" alt="" class="imgwidthheight">
    </div>

    <!--服务商圈-->
    <businessdistrict v-if="showBusinessdistrict === true" :visible.sync="showBusinessdistrict"/>

    <!-- 客户商圈 -->
    <custom v-if="showCustom === true" :visible.sync="showCustom"/>

  </div>
</template>

<script>
import EleNotice from './notice'
import { isFullscreen, toggleFullscreen } from 'ele-admin/packages/util'
import { AccountLogoutApi } from '@/api/account'
import { removeCookies } from '@/utils/cookies'

//服务商圈
import businessdistrict from '@/views/personalCenter/businessdistrict.vue'
// 引入客户圈
import custom from '@/views/personalCenter/custom.vue'

import settings from '@/config'

export default {
  name: 'EleHeaderRight',
  components: { EleNotice, businessdistrict,custom},
  emits: ['item-click', 'change-language'],
  props: {
    // 是否显示打开设置抽屉按钮
    showSetting: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    // 当前登录用户信息
    loginUser () {
      return this.$store.state.admin.userInfo
    },
    // 当前语言
    language () {
      return 'this.$i18n.locale'
    }
  },
  data () {
    return {
      // 是否全屏状态
      fullscreen: false,
      //服务商圈
      showBusinessdistrict:false,
      //客户圈
      showCustom:false,

      identity_rank:localStorage.getItem('identity_rank')
    }
  },
  methods: {
    /* 个人信息下拉菜单点击 */
    onUserDropClick (command) {
      if (command === 'logout') {
        // 调用退出登录
        this.logout();
      } else if (command === 'profile') {
        // if (this.$route.fullPath !== '/user/profile') {
        //   this.$router.push('/user/profile')
        // }
        //跳转个人中心
        this.$router.push('/personalCenter')
      }else if(command === 'wallet'){
        //跳转企业钱包
        this.$router.push('/corporateWallet')
      }else if(command === 'memberInformation'){
        //跳转会员信息
        this.$router.push('/memberInformation')
      }else if(command === 'accountSettings'){
        //跳转账号设置
        this.$router.push('/accountSettings')
      }else if(command === 'enterpriseInformation'){
        //跳转企业信息
        this.$router.push('/enterpriseInformation')
      } else if (command === 'password') {
        this.$emit('item-click', 'password')
      }
    },

    //点击消息通知
    TomessageNotification(){
      this.$router.push('/messageNotification')
    },

    /* 打开设置抽屉 */
    openSetting () {
      this.$emit('item-click', 'setting')
    },
    /* 全屏切换 */
    changeFullscreen () {
      try {
        this.fullscreen = toggleFullscreen()
      } catch (e) {
        this.$message.error('您的浏览器不支持全屏模式')
      }
    },
    /* 检查全屏状态 */
    checkFullscreen () {
      this.fullscreen = isFullscreen()
    },
    /* 切换语言 */
    changeLanguage (lang) {
      this.$emit('change-language', lang)
    },

    /* 回到首页 */
    goHome(){
      console.log('回到首页');
    },

    //点击客户圈
    openshowCustom(){
      this.showCustom = true;
    },

    // 点击服务商圈
    openbusinessdistrict(){
      this.showBusinessdistrict = true;
      // this.$emit('showbusinessdistrict',true);
    },

    /* 退出登录 */
    logout(){
      let that = this
      this.$Modal.confirm({
        title: '退出登录确认',
        content: '您确定退出当前账户吗？',
        onOk () {
          AccountLogoutApi().then((res) => {
            console.log(res)
            // superman 超级管理员
            if(localStorage.getItem('identity_rank') == 'superman'){
              that.$Message.success('您已成功退出')
              removeCookies(settings.tokenStoreName)
              removeCookies('expires_time')
              removeCookies('uuid')
              localStorage.clear()
              sessionStorage.clear()
              // 默认进入不显示弹窗
              let data = {
                showPhone: false
              }
              this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
              // 默认空手机号
              let params = {
                phone: ''
              }
              this.$addStorageEvent(1, "phone", JSON.stringify(params))

              // 默认订单号为空
              let orderid = {
                orderid: ''
              }
              this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

              // 默认呼出类型为空
              let teltype = {
                tel_type: ''
              }
              this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
              setTimeout(() => {
                // this.$router.push({ path: '/login' })
                that.$router.push('/super_login');
              }, 1500)
            }else {
              that.$Message.success('您已成功退出')
              removeCookies(settings.tokenStoreName)
              removeCookies('expires_time')
              removeCookies('uuid')
              localStorage.clear()
              sessionStorage.clear()
              // 默认进入不显示弹窗
              let data = {
                showPhone: false
              }
              this.$addStorageEvent(1, "showPhone", JSON.stringify(data))
              // 默认空手机号
              let params = {
                phone: ''
              }
              this.$addStorageEvent(1, "phone", JSON.stringify(params))

              // 默认订单号为空
              let orderid = {
                orderid: ''
              }
              this.$addStorageEvent(1, "orderid", JSON.stringify(orderid))

              // 默认呼出类型为空
              let teltype = {
                tel_type: ''
              }
              this.$addStorageEvent(1, "tel_type", JSON.stringify(teltype))
              setTimeout(() => {
                // this.$router.push({ path: '/login' })
                that.$router.push('/login');
              }, 1500)
            }


            // window.location.reload()
          }).catch((res) => {})
        }
      })
    },

  }
}
</script>

<style lang="scss" scoped>
  .el-badge__content.is-fixed{
    top: 8px;
  }
  .imgwidthheight{
    width: 18px; height: 18px;
  }
  .item {
    //margin-top: 5px;
    padding-top: 5px;
  }
</style>
