<template>
  <div class="">
    <!--<el-popover-->
    <!--    placement="right-start"-->
    <!--    width="150"-->
    <!--    trigger="hover">-->
    <!--  <el-tooltip class="item" content="上下拖动字段进行排序" placement="left" effect="light">-->
    <!--    <draggable v-model="sortModel"-->
    <!--               class="table-column-sorter" chosenClass="is-chosen" animation="true"-->
    <!--               @change="handleTableColumnSort"-->
    <!--    >-->
    <!--      <el-checkbox  v-model="column.isShow"  v-for="column in sortModel" :key="column.name"-->
    <!--                    class="sortable-column">-->
    <!--        <div style="display: flex;align-items: center;justify-content:space-between;width: 100%">-->
    <!--          <span style="margin: 5px 0">{{ column.name }}</span>-->
    <!--          <i class="el-icon-sort" style="cursor: move;text-align: right"></i>-->
    <!--        </div>-->
    <!--      </el-checkbox>-->
    <!--    </draggable>-->
    <!--  </el-tooltip>-->
    <!--  <i class="el-icon-s-unfold" style="color: #198cff" slot="reference"></i>-->
    <!--</el-popover>-->

    <div style="">
      <div class="">请选择数据列表中要显示的字段，可拖动排序</div>
      <div style="margin-bottom: 30px; border-bottom: 1px solid #eeeeee;padding-bottom: 30px; margin-top: 20px;">
        <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      </div>
    </div>

    <draggable v-model="sortModel" class="table-column-sorter" chosenClass="is-chosen" animation="true">
      <template v-for="column in sortModel">
        <el-checkbox v-if="column.label != undefined"  v-model="column.isShow"
                      class="sortable-column">
          <div style="display: flex;align-items: center;justify-content:space-between;width: 100%">
            <span style="margin: 5px 0">{{ column.label }}</span>
            <!--<i class="el-icon-sort" style="cursor: move;text-align: right"></i>-->
            <img src="@/assets/images/home/tuozhuai.png" alt="" style="width: 20px; height: 20px;">
          </div>
        </el-checkbox>
      </template>
    </draggable>

    <div style="text-align: center; margin-top: 30px">
      <el-button @click="quxiao()">取消</el-button>
      <el-button style="background: #ff9b05 !important; border: 1px solid #ff9b05 !important; color: #fff !important; margin-left: 30px;" @click="handleTableColumnSort()">确定</el-button>
    </div>

  </div>
</template>

<script>
import draggable from "vuedraggable"
export default {
  name: "PackageSortElement",
  components:{
    draggable,
  } ,
  props:{
    //接收调用组件者传参数    :AllParameters="sortModel"   sortModel是组件中自己定义的排序顺序
    //用AllParameters去接收
    AllParameters:{
      type: Array,
    }
  },
  data(){
    return{
      //接收到的数据传入 拖拽组件中进行排序
      sortModel:this.AllParameters,

      //全选
      checkAll:true
    }
  },
  methods:{
    handleTableColumnSort() {
      //当我们进行拖动的时候去调用 组件的change事件进行传参    this.sortModel是排序完成之后的顺序，拿到并且在原页面进行赋值
      this.$emit("change", this.sortModel)
      this.$emit("show", false)
    },
    quxiao(){
      this.$emit("show", false)
    },

    // 点击全选
    handleCheckAllChange(){
      console.log('1111')
      if (this.checkAll == true){
        this.sortModel.forEach(item => {
          item.isShow = true;
        })
        console.log(this.sortModel)
        this.$emit("change", this.sortModel)
      }else {
        this.sortModel.forEach(item => {
          item.isShow = false;
        })
        console.log(this.sortModel)
        this.$emit("change", this.sortModel)
      }

    },

  }
}
</script>

<style lang="scss" scoped>
.table-column-sorter {
  position: relative;

  ::v-deep .el-checkbox__label {
    width: 88%;
  }

  .title-tooltip {
    position: absolute;
    left: -150px;
    top: 50%;
  }

  ::v-deep .el-checkbox {
    width: 100%;
  }

  .sortable-column {
    padding: 3px;
    color: #aaaaaa;
    cursor: pointer;
  }

  .sortable-column:hover {
    //background: #cbedfc;
    padding: 3px;
  }
}

.el-icon-s-unfold {
  cursor: pointer;
  position: absolute;
  z-index: 998;
  right: 32.5px;
  top: 9px;
  height: 12px;
}

.title_t{
  font-size: 14px;
  font-weight: 400;
  color: #a8a8a8;
}
</style>




