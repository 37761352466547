<template>
  <div>
    <Modal
      v-model="modals"
      :z-index="100"
      scrollable
      footer-hide
      closable
      title="发送优惠券"
      :mask-closable="false"
      width="900">
      <div class="acea-row">
        <span class="sp">优惠券名称：</span>
        <Input v-model="searchWhere.coupon_title" search enter-button placeholder="请输入优惠券名称" style="width: 60%" @on-search="handleSearchs"/>
      </div>
      <Table
        :columns="columns"
        :data="couponList"
        ref="table"
        class="l-m-t25"
        :loading="loading"
        highlight-row
        no-userFrom-text="暂无数据"
        no-filtered-userFrom-text="暂无筛选结果">
        <template slot-scope="{ row }" slot="coupon_time">
          <div v-if="row.coupon_time">{{ row.coupon_time }}</div>
          <div v-else>{{ row.use_time }}</div>
        </template>
        <template slot-scope="{ row, index }" slot="action">
          <a @click="handleSendGrant(row, '发送优惠券', index)">发送</a>
        </template>
      </Table>
      <div class="acea-row row-right l-m-t25">
        <Page :total="total" show-elevator show-total @on-change="handlePageChange" :page-size="searchWhere.limit" />
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getCouponListApi
} from '@/api/users'
export default {
  name: 'SendCoupone',
  props: {
    userIds: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      modals: false,
      loading: false,
      searchWhere: {
        page: 1,
        limit: 15,
        coupon_title: '',
        receive_type: 3
      },
      couponList: [],
      columns: [
        { title: '优惠券名称', key: 'title', align: 'center', minWidth: 100 },
        { title: '优惠券面值', key: 'coupon_price', align: 'center', minWidth: 80 },
        { title: '优惠券最低消费', key: 'use_min_price', align: 'center', minWidth: 150 },
        { title: '优惠券有效期限', slot: 'coupon_time', align: 'center', minWidth: 120 },
        { title: '操作', slot: 'action', align: 'center', width: 120 }
      ],
      total: 0
    }
  },
  methods: {
    // 获取优惠券列表
    getListRequest () {
      getCouponListApi(this.searchWhere).then(res => {
        let data = res.data
        this.couponList = data.list
        this.total = data.count
        this.loading = false
      }).catch((res) => {
        this.loading = false
        this.$Message.error(res.msg)
      })
    },
    // 搜索
    handleSearchs () {
      this.getListRequest()
    },
    // 发放
    handleSendGrant (row, tit, num) {
      let delfromData = {
        title: tit,
        num: num,
        url: 'marketing/coupon/user/grant',
        method: 'post',
        ids: {
          id: row.id,
          uid: this.userIds
        }
      }
      this.$overallModal(delfromData).then((res) => {
        this.$Message.success(res.msg)
      }).catch((res) => {
        this.$Message.error(res.msg)
      })
    },
    // 分页搜索
    handlePageChange (index) {
      this.searchWhere.page = index
      this.getListRequest()
    }
  }
}
</script>

<style scoped>
.sp {
  line-height: 32px;
}
</style>
