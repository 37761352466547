import request from '@/utils/request'

// =============================
// TODO: 用户管理国度
// =============================
/**
 * 获取用户列表
 * @param params
 * @returns {*}
 */
export function getUsersListApi (params) {
  return request({
    url: 'users/users',
    method: 'get',
    params
  })
}

/**
 * 获取用户信息
 * @param uid
 * @returns {*}
 */
export function getUserInfoApi (uid) {
  return request({
    url: `users/users/user_save_info/${uid}`,
    method: 'get'
  })
}

/**
 * 获取用户详情
 * @param id
 * @returns {*}
 */
export function getUsersDetailsApi (id) {
  return request({
    url: `users/users/${id}`,
    method: 'get'
  })
}

/**
 * 获取某个用户标签
 * @param uid
 * @returns {*}
 */
export function getUsersLabelApi (uid) {
  return request({
    url: `users/label/${uid}`,
    method: 'get'
  })
}

/**
 * 会员管理详情中tab选项
 * @param data
 * @returns {*}
 */
export function getTabInfoApi (data) {
  return request({
    url: `users/one_info/${data.id}`,
    method: 'get',
    params: data.datas
  })
}

/**
 * 创建新用户
 * @param data
 * @returns {*}
 */
export function saveUsersInfoApi (data) {
  return request({
    url: 'users/users',
    method: 'post',
    data
  })
}

/**
 * 更新用户
 * @param data
 * @returns {*}
 */
export function updateUsersInfoApi (data) {
  return request({
    url: 'users/users/' + data.uid,
    method: 'put',
    data
  })
}

/**
 * 优惠券列表
 * @param params
 * @returns {*}
 */
export function getCouponListApi (params) {
  return request({
    url: 'marketing/coupon/grant',
    method: 'get',
    params
  })
}

/**
 * 获取用户分组列表
 * @param params
 * @returns {*}
 */
export function getGroupListApi (params) {
  return request({
    url: 'users/group/list',
    method: 'get',
    params
  })
}

/**
 * 获取标签总分类
 * @param params
 * @returns {*}
 */
export function getUsersLabelAllListApi (params) {
  return request({
    url: 'users/label_cate/all',
    method: 'get',
    params
  })
}

/**
 * 用户标签管理列表
 * @param params
 * @returns {*}
 */
export function getUsersLabelListApi (params) {
  return request({
    url: 'users/users_label',
    method: 'get',
    params
  })
}

/**
 * 会员标签表单生成
 * @param id
 * @param cate_id
 * @returns {*}
 */
export function addUsersLabelApi (id, cate_id) {
  return request({
    url: `users/user_label/add/${id}?cate_id=${cate_id || 0}`,
    method: 'get'
  })
}

/**
 * 保存用户标签
 * @param data
 * @returns {*}
 */
export function saveUsersLabelApi (data) {
  return request({
    url: 'user/set_label',
    method: 'post',
    data
  })
}

/**
 * 获取等级列表
 * @param params
 * @returns {*}
 */
export function getLeveListApi (params) {
  return request({
    url: 'users/level/vip_list',
    method: 'get',
    params
  })
}

