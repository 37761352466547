/**
 * 路由配置
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import setting from '@/config/config'
import EleLayout from '@/layout'
import { menuToRoutes } from 'ele-admin'
import { getCookies } from '@/utils/cookies'
import Config from '@/config'

import BasicLayout from '@/layout/index.vue'

Vue.use(VueRouter)

/**
 * 防止重定向
 * @type {{(location: RawLocation): Promise<Route>, (location: RawLocation, onComplete?: Function, onAbort?: ErrorHandler): void}}
 */
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }


// 静态路由
const routes = [
  {
    path: '/login',
    component: () => import('@/views/account/new_login.vue'),
    meta: { title: '登录' }
  },
  {
    path: '/super_login',
    component: () => import('@/views/account/super_login.vue'),
    meta: { title: '超管登录' }
  },
  {
    path: '/serviceProvider',
    query: {
      approve_status:'',
      appid:''
    },
    component: () => import('@/views/account/serviceProvider.vue'),
    meta: { title: '服务商注册' }
  },
  {
    path: '/individualDriverRegistration',
    component: () => import('@/views/account/individualDriverRegistration.vue'),
    meta: { title: '个体司机注册' }
  },
  {
    path: '*',
    component: () => import('@/views/common/error/404')
  },

  {
    path:'/',
    component:BasicLayout,
    meta: { title: '公共' },
    children:[
      {
        path:'personalCenter',
        component: () => import('@/views/personalCenter/index.vue'),
        meta: { title: '个人中心' }
      },
      {
        path:'/corporateWallet',
        component: () => import('@/views/personalCenter/corporateWallet.vue'),
        meta: { title: '企业钱包' }
      },
      {
        path:'/memberInformation',
        component: () => import('@/views/personalCenter/memberInformation.vue'),
        meta: { title: '会员信息' }
      },
      {
        path:'/upgradeMember',
        component: () => import('@/views/personalCenter/upgradeMember.vue'),
        meta: { title: '升级会员' }
      },
      {
        path:'/accountSettings',
        component: () => import('@/views/personalCenter/accountSettings.vue'),
        meta: { title: '账号设置' }
      },
      {
        path:'/enterpriseInformation',
        component: () => import('@/views/personalCenter/enterpriseInformation.vue'),
        meta: { title: '企业信息' }
      },
      {
        path:'/ActivateService',
        component: () => import('@/views/personalCenter/ActivateService.vue'),
        meta: { title: '开通服务' }
      },
      {
        path:'/withdrawalApplication',
        component: () => import('@/views/personalCenter/withdrawalApplication.vue'),
        meta: { title: '提现申请' }
      },
      {
        path:'/commodityPurchase',
        component: () => import('@/views/personalCenter/commodityPurchase.vue'),
        meta: { title: '商品购买' }
      },
      {
        path:'/productMaintenance',
        component: () => import('@/views/personalCenter/productMaintenance.vue'),
        meta: { title: '商品维护' }
      },
      {
        path:'/packagePurchaseRecord',
        component: () => import('@/views/personalCenter/packagePurchaseRecord.vue'),
        meta: { title: '套餐购买记录' }
      },
      {
        path:'/shoppingMallProductList',
        component: () => import('@/views/personalCenter/shoppingMallProductList.vue'),
        meta: { title: '商城商品列表' }
      },
      {
        path:'/investmentRecordList',
        component: () => import('@/views/personalCenter/investmentRecordList.vue'),
        meta: { title: '投资记录列表' }
      },
      {
        path:'/messageNotification',
        component: () => import('@/views/personalCenter/messageNotification.vue'),
        meta: { title: '消息通知' }
      },
      {
        path:'/orderGrabbingHall',
        component: () => import('@/views/personalCenter/orderGrabbingHall.vue'),
        meta: { title: '抢单大厅' }
      },
      {
        path:'/reconciliationInformation',
        component: () => import('@/views/reconciliationInformation/index.vue'),
        meta: { title: '对账资料' }
      },
    ]
  },

  {
    path:'/FormParser',
    component: () => import('@/views/system/formdesign/components/FormParser.vue'),
    meta: { title: '表单设计' }
  },
  {
    path:'/electroniccontract',
    component: () => import('@/views/electroniccontract/index.vue'),
    meta: { title: '电子合同' }
  },
  {
    path:'/largeScreen',
    component: () => import('@/views/largeScreen/index.vue'),
    meta: { title: '服务商大屏' }
  },
  {
    path:'/financelargeScreen',
    component: () => import('@/views/financelargeScreen/index.vue'),
    meta: { title: '财务大屏' }
  },
  {
    path:'/Alipay',
    component: () => import('@/views/personalCenter/Alipay.vue'),
    meta: { title: '支付宝支付' }
  },
  //
  // 公共页面
  {
    path: '/PublicPage',
    name: 'publicPage',
    header: 'PublicPage',
    component: BasicLayout,
    meta: { title: '开通服务' }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

// 路由守卫
router.beforeEach((to, from, next) => {
  // 修改页面标题
  // updateTitle(to)

  // 判断是否登录
  // 判断是否需要登录才可以进入
  // 判断登录拦截
  const hasToken = getCookies(Config.tokenStoreName)
  if (hasToken && hasToken != false) {
    const hasRoles = store.getters.uniqueAuth && store.getters.uniqueAuth.length > 0
    if (!hasRoles) {
      // 获取动态路由
      store.dispatch('admin/getAdminInfo').then(({ menus, home }) => {
        // menuToRoutes方法是把菜单数据转成路由数据格式
        router.addRoute({
          path: '/',
          component: EleLayout,
          redirect: setting.homePath || home,
          children: menuToRoutes(menus, (component) => import('@/views' + component))
        })
        next({ ...to, replace: true })
      }).catch(() => {
        next()
      })

      // 获取全局字典
      // store.dispatch('admin/getDict').then(({ res }) => {
      //   console.log(res)
      // })

    } else {
      next()
    }
  } else if (setting.whiteList.includes(to.path)) {
    next()
  } else {
    next({ path: '/login', query: to.path === '/' ? {} : { from: to.path } })
  }
})

router.afterEach(() => {
})

export default router

/**
 * 更新浏览器标题
 * @param route 路由
 */
function updateTitle (route) {
  if (!route.path.startsWith('/redirect/')) {
    let names = []
    if (route && route.meta && route.meta.title) {
      names.push(route.meta.title)
    }
    const appName = process.env.VUE_APP_NAME
    if (appName) {
      names.push(appName)
    }
    document.title = names.join(' - ')
  }
}


// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
