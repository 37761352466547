// 封装的 element Popconfirm 汽包提示框

/**
 * @param content 弹框中提示的内容
 * @param callback 点击确认后返回的函数
 * @param btnText 确认按钮，很多情况下确认按钮都需要有单独的文案。同理还可以增加取消按钮的文案。
 * @param type 消息类型，用于显示图标 success / info / warning / error
 */
import ElementUI from "element-ui"; //这里是引入 element
const utils = {
    confirm(content, callback, btnText, type) {
        ElementUI.MessageBox.confirm(content,
            "温馨提示", {
                confirmButtonText: btnText || "确定",
                cancelButtonText: "取消",
                type: type
            }).then(() => {
            callback()
        })
            .catch(() => {});
    },
}
export default utils
