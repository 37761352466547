// 表单验证 聚焦
export function firstInputFocus(){
  setTimeout(()=>{
    var isError= document.getElementsByClassName("is-error");
    let isErrorInput = isError[0].querySelector('input');
    let isErrortextarea = isError[0].querySelector('.el-textarea__inner');
    if(isErrorInput){
      isErrorInput.focus();
    }
    if(isErrortextarea){
      isErrortextarea.focus();
    }},100);
}
